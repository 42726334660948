import React, { useState } from "react";
import ButtonRoute from "../../components/ButtonRoute/ButtonRoute";
import { CardNftItems } from "../../components/CardNftItem/CardNftItem";
import CollectionItem from "../../components/CardCollectionItem/CardCollectionItem";
import CallToAction from "../../components/CardCallToAction/CardCallToAction";
import Error from "../../screens/Error/Error";
import ChainList from "./components/ChainList";
import PollingMessages from "./components/PollingMessages";
import EngineInput from "./components/EngineInput";
import Loading from "../../screens/Loading/Loading";
import Footer from "../../components/Footer/Footer";
import {
  USER_API_REGION,
  USER_API_ID,
  STAGE,
  BACKEND_API_ID,
  BACKEND_API_REGION,
  SITE_ID,
} from "../../../config";
import { handleGetSupportedPlatforms } from "../../functions../../../utils";
import { handleApiRequest } from "../../../api";

const axios = require("axios");

function Engine() {
  // env and storage
  const platform = localStorage.getItem("platform");
  const user = localStorage.getItem("user");
  const thisSiteId = SITE_ID;
  const getUserUrl = `https://${USER_API_ID}.execute-api.${USER_API_REGION}.amazonaws.com/${STAGE}/users/${platform}/${user}?cache=true`;
  const getSitesUrl = `https://${BACKEND_API_ID}.execute-api.${BACKEND_API_REGION}.amazonaws.com/${STAGE}/sites`;
  // state
  const [sites, setSites] = useState([]);
  const [thisSite, setThisSite] = useState({});
  const [userItems, setUserItems] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isUserSuccess, setIsUserSuccess] = useState(false);
  const [isSiteLoading, setIsSiteLoading] = useState(true);
  const [isSiteSuccess, setIsSiteSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [statusString, setStatusString] = useState("");
  // page specific state
  const [enginePlatform, setEnginePlatform] = useState(platform);
  const [supportedPlatforms, setSupportedPlatforms] = useState([]);
  // engine flow state
  const [pollCount, setPollCount] = useState(0);
  const [isOutputLoading, setIsOutputLoading] = useState(false);
  const [isOutputSubmitted, setIsOutputSubmitted] = useState(false);
  const [outputData, setOutputData] = useState({});
  const [outputSuccess, setOutputSuccess] = useState(false);

  // handle reset
  const handleResetFormData = () => {
    setIsOutputSubmitted(false);
    setIsOutputLoading(false);
    setOutputData({});
    setOutputSuccess(false);
    setPollCount(0);
  };

  const handleGenerate = () => {
    handleResetFormData();
    setIsOutputLoading(true);
    handleApiRequestLifecycle("handleEngineRequest", true, {
      random: "true",
      wallet: user,
      pollCount,
    });
  };

  //  GET users/{platform}/{user}
  React.useEffect(() => {
    if (platform && user) {
      setIsUserLoading(true);
      axios
        .get(getUserUrl)
        .then((response) => {
          setUserItems(response.data.items);
          setIsUserSuccess(true);
          setIsUserLoading(false);
        })
        .catch((error) => {
          setIsUserSuccess(false);
          setIsUserLoading(false);
          setError(error);
        });
    } else {
      // it's OK to not have a user and platform in localstorage
      setIsUserSuccess(true);
      setIsUserLoading(false);
    }
  }, [getUserUrl, platform, user]);

  // GET sites
  React.useEffect(() => {
    setIsSiteLoading(true);
    axios
      .get(getSitesUrl)
      .then((response) => {
        let sites = response.data.sites;
        let thisSite = sites[thisSiteId];
        setSites(sites);
        setThisSite(thisSite);
        setIsSiteSuccess(true);
        setIsSiteLoading(false);
        setSupportedPlatforms(handleGetSupportedPlatforms(sites));
      })
      .then((error) => {
        setIsSiteSuccess(false);
        setIsSiteLoading(false);
        setError(error);
      });
  }, [getSitesUrl, thisSiteId]);

  // handle submit
  const handleApiRequestLifecycle = async (
    lambdaFunction,
    polling = false,
    flags = {},
    pollCount = 0
  ) => {
    setIsOutputLoading(true);
    setIsOutputSubmitted(true);
    // special case just for engine page, if wallet is a flag, and doesn't match the localstorage'd user address, do not use the userItems for nfts, do not send anything for nfts
    let nfts = userItems;
    if (flags.wallet !== user) nfts = null;
    let params = {
      input: {
        collection: thisSiteId,
        platform,
        userId: user,
      },
      // nfts: [], simulate failed tx with this
      nfts,
      siteObject: thisSite,
      jobId: polling ? "" : null,
    };
    handleApiRequest(lambdaFunction, params, flags)
      .then((response) => {
        let status = response.status;
        switch (status) {
          case 202:
            let jobId = response.data.jobId;
            let interval = setInterval(() => {
              handleApiRequest(lambdaFunction, { ...params, jobId })
                .then((response) => {
                  let status = response.status;
                  switch (status) {
                    case 200:
                      setOutputData({
                        outputJson: response.data.message.json,
                        outputMetaplex: response.data.message.metaplex_uri,
                        outputKey: response.data.message.deriv_key,
                        outputImage: response.data.message.location,
                        site: response.data.site,
                        input: response.data.input,
                      });
                      setIsOutputLoading(false);
                      setOutputSuccess(true);
                      clearInterval(interval);
                      break;
                    case 202:
                      pollCount = pollCount + 1;
                      setPollCount(pollCount + 1);
                      if (pollCount > 120) {
                        setError("timeout");
                        setIsOutputLoading(false);
                        setOutputSuccess(false);
                        setIsOutputSubmitted(false);
                        clearInterval(interval);
                      }
                      break;
                    default:
                      setIsOutputLoading(false);
                      setOutputSuccess(false);
                      setIsOutputSubmitted(false);
                      clearInterval(interval);
                      break;
                  }
                })
                .catch((error) => {
                  setError(error);
                  setIsOutputLoading(false);
                  setIsOutputSubmitted(false);
                  setOutputSuccess(false);
                  clearInterval(interval);
                });
            }, 1000);
            break;

          case 200:
            setIsOutputLoading(false);
            setOutputData({
              outputJson: response.data.message.json,
              outputKey: response.data.message.deriv_key,
              outputImage: response.data.message.location,
              site: response.data.site,
              input: response.data.input,
            });
            setOutputSuccess(true);
            break;

          default:
            setIsOutputLoading(false);
            setIsOutputSubmitted(false);
            setOutputSuccess(false);
            break;
        }
      })
      .catch((error) => {
        setError(error);
        setIsOutputLoading(false);
        setOutputSuccess(false);
      });
  };

  // component props
  const chainListProps = {
    enginePlatform,
    setEnginePlatform,
    supportedPlatforms,
  };

  const EngineInputProps = {
    handleApiRequestLifecycle,
    user,
  };

  return isSiteLoading || isUserLoading ? (
    <Loading />
  ) : !isUserSuccess && !isSiteSuccess ? (
    <Error {...{ error }} />
  ) : (
    <>
      <div className="common_page-title" onClick={handleResetFormData}>
        THE CAFE ENGINE ⚙️
      </div>
      <div className="page-random_app-engine-container">
        {!isOutputLoading && !isOutputSubmitted && (
          // step 1 initial page
          <>
            <div className="page-random_engine-form-above-input-row">
              <div className="page-random_engine-form-header">
                Enter your wallet address, we'll make you some art.
              </div>
            </div>
            <div className="page-random_engine-form-input-container">
              <EngineInput {...EngineInputProps} />
            </div>
            <div className="page-random_engine-form-available-chains">
              <ChainList {...chainListProps} />
            </div>
          </>
        )}
        {isOutputLoading && isOutputSubmitted && (
          <div>
            <div className="page-random_component_random-wagmi-message">{`> browsing all ${
              Object.keys(sites).length
            } collections`}</div>
            <PollingMessages pollCount={pollCount} />
            <div className="page-random_random-wagmi-loading-message">
              - creating a derivative
            </div>
          </div>
        )}
        {!isOutputLoading && isOutputSubmitted && outputSuccess && (
          <div className="page-random_engine-output-container">
            <div className="page-random_engine-output-container-image-container">
              {/* link to external page */}
              <a
                className="page-random_engine-output-image"
                href={outputData.outputImage}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={outputData.outputImage}
                  src={outputData.outputImage}
                />
              </a>
            </div>
            <div className="common_route-buttons-container">
              <ButtonRoute
                content="view derivative & mint"
                routePath={`/derivatives/${outputData.outputKey}`}
              />
              <div className="page-random_output-card-row">
                <CardNftItems items={outputData.input.items} />
                <CollectionItem site={outputData.site} variant={"collection"} />
              </div>
              <CallToAction
                {...{ site: outputData.site, input: outputData.input }}
              />
              <ButtonRoute
                content="view all collections"
                routePath="/collections"
              />
              <ButtonRoute
                content="generate another"
                specialHandler={handleGenerate}
                variant="vanilla"
              />
              <ButtonRoute
                content="reset"
                specialHandler={handleResetFormData}
                variant="vanilla"
              />
            </div>
          </div>
        )}
        {!isOutputLoading && isOutputSubmitted && !outputSuccess && (
          <div>
            FAILED output here
            <ButtonRoute
              content="reset"
              specialHandler={handleResetFormData}
              variant="vanilla"
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Engine;
