import { handlePopulateItems } from "../utils";
import { handleApiRequest } from "../../../../api";
const axios = require("axios");
function AppFormSubmitButton({
  setIsOutputLoading,
  setIsOutputSubmitted,
  thisSiteId,
  platform,
  user,
  formData,
  userItems,
  thisSite,
  setOutputData,
  setError,
  areRequiredImagesSatisified,
}) {
  const handleApiRequestLifecycle = async (
    lambdaFunction,
    polling = false,
    flags = {}
  ) => {
    setIsOutputLoading(true);
    setIsOutputSubmitted(true);
    let params = {
      input: {
        collection: thisSiteId,
        platform,
        userId: user,
        imageType: formData.imageType,
        derivativeType: formData.derivativeType,
        frameStyle: formData.frameStyle,
        items: handlePopulateItems(userItems, formData),
      },
      nfts: userItems,
      siteObject: thisSite,
      jobId: polling ? "" : null,
    };
    handleApiRequest(lambdaFunction, params, flags).then((response) => {
      let status = response.status;
      switch (status) {
        case 202:
          let jobId = response.data.jobId;
          let pollCount = 0;
          let interval = setInterval(() => {
            handleApiRequest(lambdaFunction, { ...params, jobId })
              .then((response) => {
                let status = response.status;
                switch (status) {
                  case 200:
                    axios
                      .get(response.data.message.metaplex_uri)
                      .then((response2) => {
                        setOutputData({
                          outputJson: response.data.message.json,
                          outputMetaplex: response.data.message.metaplex_uri,
                          outputKey: response.data.message.deriv_key,
                          outputImage: response.data.message.location,
                          token: response2.data,
                          // get the string after the last / and before the .
                          tokenAddress: response.data.message.metaplex_uri
                            .split("/")
                            [
                              response.data.message.metaplex_uri.split("/")
                                .length - 1
                            ].split(".")[0],
                        });
                      });
                    setIsOutputLoading(false);
                    clearInterval(interval);
                    break;
                  case 202:
                    pollCount++;
                    if (pollCount > 120) {
                      setError("timeout");
                      setIsOutputLoading(false);
                      setIsOutputSubmitted(false);
                      clearInterval(interval);
                    }
                    break;
                  default:
                    setIsOutputLoading(false);
                    clearInterval(interval);
                    break;
                }
              })
              .catch((error) => {
                setError(error);
                setIsOutputLoading(false);
                setIsOutputSubmitted(false);
                clearInterval(interval);
              });
          }, 1000);
          break;

        case 200:
          setIsOutputLoading(false);
          setOutputData({
            outputJson: response.data.message.json,
            outputKey: response.data.message.deriv_key,
            outputImage: response.data.message.location,
          });
          break;

        default:
          setIsOutputLoading(false);
          setIsOutputSubmitted(false);
          break;
      }
    });
  };

  return (
    <div className="page-create_component_app-form-staging-area-submit-container">
      <button
        disabled={!areRequiredImagesSatisified}
        onClick={() => {
          handleApiRequestLifecycle("handleEngineRequest", true);
        }}
      >
        submit
      </button>
    </div>
  );
}

export default AppFormSubmitButton;
