import React from "react";
import { USER_API_REGION, USER_API_ID, STAGE } from "../../../config";
import TopNavCollectionSearch from "./components/TopNavCollectionSearch";
import TopNavWallet from "./components/TopNavWallet";
const axios = require("axios");

const handleRefreshUser = async () => {
  // get user value from localstorage
  const user = localStorage.getItem("user");
  const platform = localStorage.getItem("platform");
  await axios.get(
    `https://${USER_API_ID}.execute-api.${USER_API_REGION}.amazonaws.com/${STAGE}/users/${platform}/${user}`
  );
  window.location.reload();
};

function TopNavLogo({ pageName, path }) {
  return (
    <div className="component-top-nav_top-nav-logo">
      <a href={path}>{pageName}</a>
    </div>
  );
}
function TopNavItem({ pageName, path }) {
  return (
    <div className="component-top-nav_top-nav-item">
      <a href={path}>{pageName}</a>
    </div>
  );
}

function TopNav({ pageObjects }) {
  return (
    <div className="component-top-nav_top-nav-container">
      <div className="component-top-nav_top-nav">
        <div className="component-top-nav_logo-container">
          <TopNavLogo
            pageName={pageObjects.landing.pageName}
            path={pageObjects.landing.path}
          />
        </div>
        <div className="component-top-nav_search-container">
          <TopNavCollectionSearch />
        </div>
        <div className="component-top-nav_top-nav-items-container">
          <TopNavItem
            pageName={pageObjects.create.pageName}
            path={pageObjects.create.path}
          />
          <TopNavItem
            pageName={pageObjects.collections.pageName}
            path={pageObjects.collections.path}
          />
          <TopNavItem
            pageName={pageObjects.library.pageName}
            path={pageObjects.library.path}
          />
        </div>
        <TopNavWallet handleRefreshUser={handleRefreshUser} />
      </div>
    </div>
  );
}

export default TopNav;
