import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../screens/Error/Error";
import Loading from "../../screens/Loading/Loading";
import Footer from "../../components/Footer/Footer";
import PageHeader from "../../components/Headers/Headers";
import InventoryItem from "./components/InventoryItem";
import { getUserTokens } from "../../../api/wagmi-users-api";

function PageLibrary() {
  // env and api
  const { platform, userId } = useParams();

  //   state
  const [error, setError] = React.useState(null);
  const [tokens, setTokens] = React.useState([]);
  const [isSuccessTokens, setIsSuccessTokens] = React.useState(false);
  const [isLoadingTokens, setIsLoadingTokens] = React.useState(true);

  // GET user tokens
  React.useEffect(() => {
    getUserTokens(platform, userId)
      .then((response) => {
        setIsSuccessTokens(true);
        setIsLoadingTokens(false);
        setTokens(response.data);
      })
      .catch((error) => {
        setIsSuccessTokens(false);
        setIsLoadingTokens(false);
        setError(error);
      });
  }, [platform, userId]);

  return isLoadingTokens ? (
    <Loading />
  ) : !isSuccessTokens ? (
    <Error {...{ error }} />
  ) : (
    <>
      <div className="global-page-container">
        <PageHeader pageName={"Library"} />
        <div className="page-library_inventory-page-container">
          {tokens ? (
            <div>
              <div className="page-library_inventory-items-container">
                {tokens.map((token, i) => (
                  <InventoryItem {...{ token }} key={i} />
                ))}
              </div>
            </div>
          ) : (
            <div className="page-library_inventory-items-empty-container">
              <div className="page-library_inventory-items-empty-container-text">
                You don't have any derivatives yet!
              </div>
              <div className="page-library_inventory-items-empty-container-text">
                <a href="/collections">go make one.</a>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PageLibrary;
