import React, { useEffect, useState } from "react";
import { getThisSite } from "../../../api/wagmi-api-v2";
import ButtonSocialLink from "../ButtonSocialLink/ButtonSocialLink";

function BarProjectInfo() {
  const [siteObject, setSiteObject] = useState({});
  // GET site
  useEffect(() => {
    getThisSite().then((response) => {
      setSiteObject(response.data.site);
    });
  }, []);

  let socials = [];
  for (let key in siteObject.links) {
    if (siteObject.links[key]) {
      if (key !== "wagmicafe") {
        socials.push({ url: siteObject.links[key], name: key });
      }
    }
  }

  const loaded = siteObject.siteDisplay;
  return (
    <div className="component-bar-project-info_bar-container">
      <div className="component-bar-project-info_bar">
        <div className="component-bar-project-info_bar-avitar-section">
          {!loaded ? (
            <div className="component-bar-project-info_bar-avitar-section-image-loading" />
          ) : (
            <div className="component-bar-project-info_bar-avitar-section-image">
              <img src={siteObject.assets.images.icon} alt="" />
            </div>
          )}
          {!loaded ? (
            <div className="component-bar-project-info_bar-avitar-section-name-loading" />
          ) : (
            <div className="component-bar-project-info_bar-avitar-section-name">
              {siteObject.siteDisplay.siteDisplayName}
            </div>
          )}
        </div>
        {!loaded ? (
          <div className="component-bar-project-info_bar-links-section">
            <div className="component-bar-project-info_bar-links-section-link" />
            <div className="component-bar-project-info_bar-links-section-link" />
            <div className="component-bar-project-info_bar-links-section-link" />
          </div>
        ) : (
          <div className="component-bar-project-info_bar-links-section">
            {socials.map((social, index) => {
              return (
                <ButtonSocialLink
                  key={index}
                  socialName={social.name}
                  url={social.url}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default BarProjectInfo;
