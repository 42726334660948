import React from "react";
import { API_URL_GET_SITE, SITE_ID } from "../../../config";
import ButtonSocialLink from "../ButtonSocialLink/ButtonSocialLink";
const axios = require("axios");

function CardCollectionInfoBox() {
  // get siteObject
  const [siteObject, setSiteObject] = React.useState({});
  const [isSiteObjectLoading, setSiteObjectIsLoading] = React.useState(true);

  const [error, setError] = React.useState(false);

  // GET siteObject
  React.useEffect(() => {
    axios
      .get(API_URL_GET_SITE + SITE_ID)
      .then((response) => {
        setSiteObject(response.data.site);
        setSiteObjectIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setSiteObjectIsLoading(false);
      });
  }, []);

  let socials = [];
  for (let key in siteObject.links) {
    if (siteObject.links[key]) {
      if (key !== "wagmicafe") {
        socials.push({ url: siteObject.links[key], name: key });
      }
    }
  }

  return isSiteObjectLoading ? (
    <div></div>
  ) : !siteObject ? (
    <div></div>
  ) : (
    <div className="component-card-collection-info-box_root-page-header-container">
      <div className="component-card-collection-info-box_root-page-header-desc-container">
        {/* section 1 */}
        <div className="component-card-collection-info-box_root-page-header-desc-container-sec-1">
          <div className="component-card-collection-info-box_root-page-header-desc-image">
            <img src={siteObject.assets.images.icon} alt="profile" />
          </div>
          <div className="component-card-collection-info-box_root-page-header-desc-mint-link">
            <div className="component-card-collection-info-box_root-page-header-desc-mint-icon"></div>
            <div className="component-card-collection-info-box_root-page-header-desc-mint-message">
              mintable
            </div>
          </div>
        </div>
        {/* section 2 */}
        <div className="component-card-collection-info-box_root-page-header-desc-container-sec-2">
          {/* section 2a */}
          <div className="component-card-collection-info-box_root-page-header-desc-container-sec-2a">
            <div className="component-card-collection-info-box_root-page-header-desc-header">
              <div className="component-card-collection-info-box_root-page-header-title">
                {siteObject.siteDisplay.siteDisplayName}
              </div>
            </div>
            <div className="component-card-collection-info-box_root-page-header-desc-body">
              {siteObject.siteDisplay.siteDescription}
            </div>
          </div>
          <div className="component-card-collection-info-box_root-page-header-desc-links-container">
            <div className="component-card-collection-info-box_root-page-header-desc-links">
              {socials.map((social, index) => {
                return (
                  <ButtonSocialLink
                    key={index}
                    socialName={social.name}
                    url={social.url}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardCollectionInfoBox;
