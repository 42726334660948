function PollingMessages({ pollCount }) {
  const messages = [
    "> ok you have a lot of nfts... one sec.",
    "> ok whale, you really have a lot of nfts.",
    "> chad.",
    "> SAM?",
    "> k you probably broke it, thanks.",
  ];
  return (
    <>
      {pollCount > 15 && (
        <div className="page-random_component_random-wagmi-message">
          {messages[0]}
        </div>
      )}
      {pollCount > 30 && (
        <div className="page-random_component_random-wagmi-message">
          {messages[1]}
        </div>
      )}
      {pollCount > 45 && (
        <div className="page-random_component_random-wagmi-message">
          {messages[2]}
        </div>
      )}
      {pollCount > 85 && (
        <div className="page-random_component_random-wagmi-message">
          {messages[3]}
        </div>
      )}
      {pollCount > 160 && (
        <div className="page-random_component_random-wagmi-message">
          {messages[4]}
        </div>
      )}
    </>
  );
}

export default PollingMessages;
