import React, { useEffect, useCallback } from "react";
import { USER_API_REGION, USER_API_ID, STAGE } from "../../../config";
import { handleSetLocalStorage } from "../../../utils";
const axios = require("axios");

function ModalLogin({ loginObject, setShowModal }) {
  const { header, content, placeholder, loginApiObj } = loginObject;
  const [inputValue, setInputValue] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [statusString, setStatusString] = React.useState("");
  const [getMethod, setGetMethod] = React.useState(loginApiObj.default.method);
  const [items, setItems] = React.useState([]);
  const [isWarning, setIsWarning] = React.useState(false);

  const handleEsc = useCallback(
    (event) => {
      if (event.key === "Escape") setShowModal(false);
    },
    [setShowModal]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleEsc, false);
    return () => {
      document.removeEventListener("keydown", handleEsc, false);
    };
  }, [handleEsc]);

  const handleReset = () => {
    setInputValue("");
    setIsSubmitted(false);
    setIsLoading(false);
    setError(null);
    setIsSuccess(false);
    setStatusString("");
    setGetMethod(loginApiObj.default.method);
    setItems([]);
    setIsWarning(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://${USER_API_ID}.execute-api.${USER_API_REGION}.amazonaws.com/${STAGE}/users/${getMethod}/${inputValue}`
      );
      setItems(response.data.items);
      if (response.data.items && response.data.items.length < 1) {
        setIsWarning(true);
        setStatusString(`Nothing found for ${inputValue}`);
      }
      setIsSuccess(true);
      setIsLoading(false);
      setError(null);
      handleSetLocalStorage([
        { key: "platform", value: response.data.platform },
        { key: "user", value: response.data.user },
      ]);
    } catch (error) {
      setError(error);
      setIsSuccess(false);
      setIsLoading(false);
      setIsSubmitted(false);
    }
  };
  // useeffect to set statusString on loading, error, succcess
  useEffect(() => {
    if (isLoading) {
      setStatusString("Loading...");
    } else if (error) {
      setStatusString(
        `Error: ${error.message}. Is this a valid ${placeholder}?`
      );
    } else if (isSuccess) {
      const itemsFound = items.length;
      let prefix = itemsFound > 0 ? "Success!" : "Sorry...";
      let suffix = itemsFound > 0 ? "" : "Is this a valid " + placeholder + "?";
      setStatusString(`${prefix} ${items.length} items found. ${suffix}`);
    }
  }, [isLoading, error, isSuccess, items, loginApiObj, placeholder]);

  return (
    <div className="modal-login-adapter-modal">
      <div className="modal-login-overlay-container modal-login-adapter-modal-fade-in">
        <div className="modal-login-wrapper">
          <div className="modal-login-header">{header}</div>
          <div className="modal-login-content">
            <div className="modal-login-content-text">
              <ul>
                {content.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="modal-login-input-container">
            <input
              className="modal-login-input"
              placeholder={placeholder}
              autoFocus
              onChange={(event) => {
                if (
                  loginApiObj.optional &&
                  loginApiObj.optional.regex &&
                  event.target.value.match(
                    new RegExp(loginApiObj.optional.regex)
                  )
                ) {
                  setGetMethod(loginApiObj.optional.method);
                } else {
                  setGetMethod(loginApiObj.default.method);
                }
                setInputValue(event.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSubmit(event);
                }
              }}
              // handle backspace
              onKeyDown={(event) => {
                if (event.key === "Backspace") {
                  handleReset();
                }
              }}
            />
            <button
              className="modal-login-input-button"
              onClick={handleSubmit}
              disabled={isSubmitted || inputValue.length < 1}
            >
              submit
            </button>
          </div>
          <div className="modal-status-container">
            <a href="/">
              {isSuccess && !isWarning ? "Continue with this address." : ""}
            </a>
            <div className="modal-get-method-tooltip">{getMethod}</div>
            <div
              className={`modal-get-method-status ${
                isLoading
                  ? "status-loading"
                  : isWarning
                  ? "status-warning"
                  : isSuccess
                  ? "status-success"
                  : "status-error"
              }`}
            >
              {statusString}
            </div>
          </div>
          <div
            className="modal-esc-close-button"
            onClick={() => {
              handleReset();
              setShowModal(false);
            }}
          >
            {" "}
            +
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalLogin;
