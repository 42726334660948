import React from "react";
import InventoryItem from "../PageLibrary/components/InventoryItem";
import { parseFormatting } from "../../../utils";
import CardCollectionInfoBox from "../../components/CardCollectionInfoBox/CardCollectionInfoBox";
import Footer from "../../components/Footer/Footer";
import { getUserTokensByCollection } from "../../../api/wagmi-users-api";
import { findFirstSquareImageFromGetTokensRequest } from "../../../utils";
import { SITE_ID } from "../../../config";
import BarProjectInfo from "../../components/BarProjectInfo/BarProjectInfo";

// get platform and user from localstorage
const platform = localStorage.getItem("platform");

function SectionContentTitle({ superTitle, title, subTitle }) {
  return (
    <div className="page-landing_section-content-text-container">
      <div className="page-landing_section-content-text">
        <h3>{superTitle}</h3>
        <h1>{parseFormatting(title)}</h1>
        <h4>{subTitle}</h4>
      </div>
      <div className="page-landing_section-content-text-container-buttons">
        <div className="page-landing_section-content-text-container-buttons-container">
          <a
            className="page-landing_section-content-text-container-button-launch-app"
            href="/app"
          >
            LAUNCH APP
          </a>
          <a
            className="page-landing_section-content-text-container-button-how"
            href="#how"
          >
            How it works
          </a>
        </div>
      </div>
    </div>
  );
}

function SectionContent({ superTitle, title, subTitle }) {
  return (
    <div className="page-landing_section-content-text-container">
      <div className="page-landing_section-content-text">
        <h3>{superTitle}</h3>
        <h1>{parseFormatting(title)}</h1>
        <h4>{subTitle}</h4>
      </div>
    </div>
  );
}

function StepLine({ step }) {
  // horizontal line with step in the how
  return (
    <div className="page-landing_section-content-text-container-step-line">
      <div className="page-landing_section-content-text-container-step-line-line"></div>
      <div className="page-landing_section-content-text-container-step-line-step">
        {step}
      </div>
      <div className="page-landing_section-content-text-container-step-line-line"></div>
    </div>
  );
}

function Carousel() {
  const [tokens, setTokens] = React.useState([]);

  React.useEffect(() => {
    getUserTokensByCollection(
      platform,
      "cafeUdFbVD3KCwawgwBKzuu5qj4eHzUNdhhTSM1rFxe",
      SITE_ID
    ).then((response) => {
      if (response.status === 200) {
        setTokens(response.data);
      } else {
        setTokens([]);
      }
    });
  }, []);

  return tokens.length > 0 ? (
    <div className="page-landing_carousel-items-container">
      {tokens.map((token, i) => (
        <InventoryItem token={token} key={i} />
      ))}
    </div>
  ) : null;
}

function Landing() {
  const [exampleToken, setExampleToken] = React.useState(undefined);

  React.useEffect(() => {
    getUserTokensByCollection(
      platform,
      "cafeUdFbVD3KCwawgwBKzuu5qj4eHzUNdhhTSM1rFxe",
      SITE_ID
    ).then((response) => {
      if (response.status === 200) {
        setExampleToken(
          findFirstSquareImageFromGetTokensRequest(response.data)
        );
      } else {
        setExampleToken([]);
      }
    });
  }, []);

  return (
    <>
      <div className="global-page-container">
        <div className="page-landing_section-content-container">
          <SectionContentTitle
            {...{
              superTitle: "Generate custom artwork with your collectibles",
              title: "The __Custom Artwork Engine__ built on Solana",
              subTitle:
                "The Wagmi Cafe is a free-to-use image engine that allows NFT collectors to generate custom artwork using their collectibles.",
            }}
          />
          <div className="page-landing_section-content-item-container-token">
            <div className="margin-wrapper">
              <BarProjectInfo />
            </div>
            <InventoryItem token={exampleToken} />
          </div>
        </div>
        <div id="how"></div>
        <StepLine step={"Step 1/3"} />
        <div className="page-landing_section-content-container">
          <SectionContent
            {...{
              superTitle: "Import",
              title: "Select NFTs from Wallet",
              subTitle:
                "Select the NFT you would like to create custom artwork with.",
            }}
          />
          <div className="page-landing_section-content-item-container-token">
            <div className="page-landing_section-content-item-container-token">
              <InventoryItem token={exampleToken} />
            </div>
          </div>
        </div>
        <StepLine step={"Step 2/3"} />
        <div className="page-landing_section-content-container">
          <SectionContent
            {...{
              superTitle: "Create",
              title: "__Choose a Collection__",
              subTitle:
                "We automate artwork generation for many collections on Solana. Input your NFTs and we'll create the custom for you.",
            }}
          />
          <div className="page-landing_section-content-item-container-infobox">
            <CardCollectionInfoBox />
          </div>
        </div>
        <div className="page-landing_carousel-title-container">
          Gallery: {SITE_ID}
          <Carousel />
        </div>
        <StepLine step={"Step 3/3"} />
        <div className="page-landing_section-content-container-bg-wrapper">
          <div className="page-landing_section-content-container">
            <SectionContent
              {...{
                superTitle: "Mint",
                title: "Yours __Forever__",
                subTitle:
                  "Once your artwork is generated, mint it to your wallet for safekeeping.",
              }}
            />
            <div className="page-landing_section-content-item-container-infobox">
              <div className="page-landing_section-content-item-container-token">
                <InventoryItem token={exampleToken} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Landing;
