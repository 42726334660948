import React from "react";
import { useNavigate } from "react-router-dom";

function ButtonRoute({ content, routePath, specialHandler, variant }) {
  variant = variant || "";
  const navigate = useNavigate();
  const routeChange = () => {
    navigate(routePath);
  };

  if (!routePath) {
    return (
      <div className="component-button_route-button" onClick={specialHandler}>
        <div className={`component-button_route-button-text ${variant}`}>
          {content}
        </div>
      </div>
    );
  } else {
    return (
      <div className="component-button_route-button" onClick={routeChange}>
        <div className={`component-button_route-button-text ${variant}`}>
          {content}
        </div>
      </div>
    );
  }
}

export default ButtonRoute;
