import React from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/Headers/Headers";
import InventoryItem from "../PageLibrary/components/InventoryItem";
import { getToken } from "../../../api/wagmi-users-api";
import ButtonMint from "../../components/ButtonMint/ButtonMint";
import ButtonSaveAs from "../../components/ButtonSaveAs/ButtonSaveAs";
import Error from "../../screens/Error/Error";
import Loading from "../../screens/Loading/Loading";

function PageToken() {
  const { tokenAddress } = useParams();

  // state
  const [error, setError] = React.useState(null);
  // token
  const [token, setToken] = React.useState(null);
  const [isSuccessToken, setIsSuccessToken] = React.useState(false);
  const [isLoadingToken, setIsLoadingToken] = React.useState(true);

  // useEffect
  React.useEffect(() => {
    getToken(tokenAddress)
      .then((response) => {
        setToken(response.data);
        setIsSuccessToken(true);
        setIsLoadingToken(false);
      })
      .catch((error) => {
        setIsSuccessToken(false);
        setIsLoadingToken(false);
        setError(error);
      });
  }, [tokenAddress]);

  return isLoadingToken ? (
    <Loading />
  ) : !isSuccessToken ? (
    <Error {...{ error }} />
  ) : (
    <div className="global-page-container">
      <PageHeader pageName={"Token"} />
      <div className="page-token_token-container">
        <div>
          <InventoryItem token={token} />
          <ButtonMint {...{ token, tokenAddress }} />
          <ButtonSaveAs {...{ token, tokenAddress }} />
        </div>
      </div>
    </div>
  );
}

export default PageToken;
