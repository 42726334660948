import React from "react";

function Loading() {
  return (
    <div className="loading-cover">
      <div className="loading-text-small">loading...</div>
    </div>
  );
}

export default Loading;
