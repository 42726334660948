import React from "react";
import { handleMissingImage } from "../../../../utils";
import ModalMint from "../../../components/ModalMint/ModalMint";
import { getToken } from "../../../../api/wagmi-users-api";

function InventoryItem({ token, tokenAddress }) {
  // state
  const [tokenIn, setTokenIn] = React.useState(token);
  const [isSuccessToken, setIsSuccessToken] = React.useState(false);
  const [isLoadingToken, setIsLoadingToken] = React.useState(true);

  React.useEffect(() => {
    if (token || tokenAddress) {
      if (!token) {
        getToken(tokenAddress)
          .then((response) => {
            setTokenIn(response.data);
            setIsSuccessToken(true);
            setIsLoadingToken(false);
          })
          .catch((error) => {
            setIsSuccessToken(false);
            setIsLoadingToken(false);
          });
      } else {
        setTokenIn(token);
        setIsSuccessToken(true);
        setIsLoadingToken(false);
      }
    }
  }, [token, tokenAddress]);

  if (!tokenAddress && token) {
    tokenAddress = token.image.split("/").pop().split(".").shift();
  }

  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <div className="page-library_component_inventory-item">
        {isLoadingToken || (!token && !tokenAddress) ? (
          <div className="page-libray_compnent_inventory-item-loading" />
        ) : !isSuccessToken ? (
          <div>failed</div>
        ) : (
          <div className="page-library_component_inventory-item-image-container">
            <div className="page-library_component_inventory-item-image">
              <a href={`/token/${tokenAddress}`}>
                <img
                  src={tokenIn.image}
                  alt={tokenIn.name}
                  onError={({ currentTarget }) => {
                    handleMissingImage(currentTarget, "token");
                  }}
                />
              </a>
            </div>
            <div className="page-library_component_inventory-item-details-container">
              {tokenIn.attributes.map((attribute, i) => (
                <div
                  className="page-library_component_inventory-item-detail-container"
                  key={i}
                >
                  <div className="page-library_component_inventory-item-detail-title">
                    {attribute.trait_type}
                  </div>
                  <div className="page-library_component_inventory-item-detail-content">
                    {attribute.value}
                  </div>
                </div>
              ))}
              <div
                className="page-library_component_inventory-item-mint-container"
                onClick={() => {
                  setShowModal(true);
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
      {showModal && (
        <ModalMint {...{ token: tokenIn, tokenAddress, setShowModal }} />
      )}
    </>
  );
}

export default InventoryItem;
