import { STAGE, BACKEND_API_REGION, BACKEND_API_ID } from "../../config";
const dotenv = require("dotenv");
dotenv.config();
const axios = require("axios");
const API_ENDPOINT = `https://${BACKEND_API_ID}.execute-api.${BACKEND_API_REGION}.amazonaws.com/${STAGE}`;

const getItemsOnMagicEden = async (symbol, count, offset) => {
  let url = `${API_ENDPOINT}/market/magiceden/${symbol}?limit=${count}&offset=${offset}`;
  const response = await axios.get(url);
  return response.data;
};

export { getItemsOnMagicEden };
