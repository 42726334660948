function AppFormStagingPreview() {
  return (
    <div className="create-page_component_app-form-staging-area-preview-container">
      <div className="create-page_component_app-form-staging-area-preview-container-image-container">
        <div className="create-page_component_app-form-staging-area-preview-container-image-container-label">
          preview
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/placeholders/no_preview_available.png`}
          alt="staging preview"
        />
      </div>
    </div>
  );
}

export default AppFormStagingPreview;
