import {
  API_URL_GET_TOKEN,
  API_URL_GET_USER_TOKENS,
  API_URL_GET_DERIVATIVE,
  API_URL_GET_NFTS,
} from "../../config";
const axios = require("axios");
const getToken = async (tokenId) => {
  const url = `${API_URL_GET_TOKEN}${tokenId}`;
  return axios.get(url);
};

const getUserTokens = async (platform, userId) => {
  platform = platform ? platform : "solana";
  const url = `${API_URL_GET_USER_TOKENS}${platform}/${userId}`;
  return axios.get(url);
};

const getUserTokensByCollection = async (platform, userId, collection) => {
  platform = platform ? platform : "solana";
  const url = `${API_URL_GET_USER_TOKENS}${platform}/${userId}/${collection}`;
  return axios.get(url);
};

const getDerivative = async (derivativeId) => {
  const url = `${API_URL_GET_DERIVATIVE}${derivativeId}`;
  return axios.get(url);
};

const getUserNftsByPlatforms = async (platform, userId, cache = true) => {
  const url = `${API_URL_GET_NFTS}${platform}/${userId}?cache=${cache}`;
  return axios.get(url);
};

export {
  getToken,
  getUserTokens,
  getUserTokensByCollection,
  getDerivative,
  getUserNftsByPlatforms,
};
