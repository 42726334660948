import React from "react";
import { partitionSiteItemsFromNonSiteItemsGivenSiteAndUserItemsV2 } from "../../../../utils";
import { getItemsOnMagicEden } from "../../../../api/magiceden";
import { handleMissingImage } from "../../../../utils";

function ItemCarousel({
  i,
  items,
  requiredImage,
  formData,
  setFormData,
  setUserItems,
  thisSite,
}) {
  const [inputValue, setInputValue] = React.useState("");
  const [filterOn, setFilterOn] = React.useState(true);
  const [isMarketLoading, setIsMarketLoading] = React.useState(false);
  const handleAddItemToStagingArea = (uuid) => {
    let upper = requiredImage.requiredNumber.upper;
    const previousUuids = formData.images[requiredImage.imageType] || [];
    let newUuids = [...previousUuids, uuid];
    if (newUuids.length > upper) {
      // slice off the first item
      newUuids = newUuids.slice(1);
    }

    setFormData({
      ...formData,
      images: {
        ...formData.images,
        [requiredImage.imageType]: newUuids,
      },
    });
  };

  const handleMagicEdenGet = async (symbols) => {
    setIsMarketLoading(true);
    let magicEdenItems = [];
    for (let symbol of symbols) {
      magicEdenItems.push(getItemsOnMagicEden(symbol, 20, 0));
    }

    let allResults = [];
    try {
      let results = await Promise.all(magicEdenItems);
      // flatten the results
      allResults = results.reduce((acc, curr) => {
        return [...acc, ...curr];
      }, []);
      setIsMarketLoading(false);
      setUserItems([...allResults, ...items]);
    } catch (error) {
      setIsMarketLoading(false);
    }
  };

  const handleManualImageSubmit = (e) => {
    setFilterOn(false);
    e.preventDefault();
    // return if input is empty
    if (inputValue === "") {
      return;
    }
    let imageUrl = inputValue;
    let uuid = (imageUrl) =>
      imageUrl.split("").reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    setUserItems((items) => [
      {
        name: "manual item",
        imageUrl,
        uuid,
      },
      ...items,
    ]);
    setInputValue("");
  };

  let pItems = [];
  let itemsToRender = [];
  let renderChoice = false;

  if (requiredImage.overrideImages) {
    itemsToRender = requiredImage.overrideImages;
  } else {
    pItems = partitionSiteItemsFromNonSiteItemsGivenSiteAndUserItemsV2(
      thisSite,
      items,
      requiredImage.filters
    );

    switch (filterOn) {
      case true:
        itemsToRender = pItems[0];
        break;
      default:
        itemsToRender = [...pItems[0], ...pItems[1]];
        break;
    }
    renderChoice = pItems[1].length > 0;
  }

  const thisImageType = requiredImage.imageType;
  const rowDescriptor = `${thisImageType} image:`;

  return (
    <>
      <hr className="divider grey" />
      <div className="page-create_component_item-carousel-row-descriptor-container">
        <div className="page-create_component_item-carousel-row-descriptor">
          {rowDescriptor}
        </div>
        {renderChoice && (
          <div
            className={`page-create_component_item-carousel-row-descriptor-filter-button ${
              filterOn ? "selected" : ""
            }`}
            onClick={() => setFilterOn(!filterOn)}
          >
            filter
          </div>
        )}
      </div>
      <div className="page-create_component_carousel-container">
        {itemsToRender.length > 0 ? (
          <div className="page-create_component_carousel">
            {itemsToRender.map((item, i) => (
              <div
                className="page-create_component_carousel-item-container"
                key={i}
              >
                <div
                  className="page-create_component_carousel-item"
                  onClick={() => handleAddItemToStagingArea(item.uuid)}
                >
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    onError={({ currentTarget }) => {
                      handleMissingImage(currentTarget, "token");
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="page-create_component_carousel-empty-container">
            <div className="page-create_component_carousel-empty-container-text">
              {`no eligible ${requiredImage.imageType} images found in wallet`}
            </div>
            {thisSite.collection.markets &&
              thisSite.collection.markets.magiceden.symbols &&
              (isMarketLoading ? (
                <div className="page-create_component_carousel-empty-container-text underline">
                  loading...
                </div>
              ) : (
                <div
                  className="page-create_component_carousel-empty-container-text underline"
                  onClick={() =>
                    handleMagicEdenGet(
                      thisSite.collection.markets.magiceden.symbols
                    )
                  }
                >
                  {`load from MagicEden`}
                </div>
              ))}
          </div>
        )}
        <div className="page-create_component_carousel-item-enter-manually-row">
          <input
            className="page-create_component_carousel-item-enter-manually-search-bar"
            onKeyPress={(e) => {
              if (e.key === "Enter") handleManualImageSubmit(e);
            }}
            placeholder="enter image url (optional)"
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
          ></input>
          <button
            className="page-create_component_carousel-item-enter-manually-search-bar-go-button"
            onClick={(e) => handleManualImageSubmit(e)}
          >
            go
          </button>
        </div>
      </div>
    </>
  );
}

export default ItemCarousel;
