import { handleRevertFormData } from "../utils";
function DerivativeTypeCarousel({ thisSite, formData, setFormData }) {
  // prefer the default image type if it exists, else pick the first one
  let imageType = formData.imageType;
  imageType = thisSite.imageTypes[imageType]
    ? imageType
    : Object.keys(thisSite.imageTypes)[0];

  return (
    <div className="page-create_component_derivative-type-carousel">
      {Object.keys(thisSite.imageTypes[imageType].derivativeTypes).map(
        (derivativeType) => (
          <button
            className={`page-create_component_derivative-type-carousel-item ${
              derivativeType === formData.derivativeType ? "selected" : ""
            }`}
            disabled={derivativeType === formData.derivativeType}
            onClick={() => {
              setFormData({
                ...handleRevertFormData(formData, thisSite, {
                  derivativeType,
                }),
                derivativeType,
              });
            }}
            key={derivativeType}
          >
            <div className="page-create_component_derivative-type-carousel-item-text">
              {derivativeType}
            </div>
          </button>
        )
      )}
    </div>
  );
}

export default DerivativeTypeCarousel;
