// import { API_URL_GET_TOKEN, API_URL_GET_USER_TOKENS } from "../../config";
// const axios = require("axios");

// const getToken = async (tokenId) => {
//   const url = `${API_URL_GET_TOKEN}${tokenId}`;
//   return axios.get(url);
// };

// const getUserTokens = async (platform, userId) => {
//   const url = `${API_URL_GET_USER_TOKENS}${platform}/${userId}`;
//   return axios.get(url);
// };

// export { getToken, getUserTokens };

import { API_URL_GET_SITE, SITE_ID } from "../../config";
const axios = require("axios");

const getSites = async () => {
  const url = `${API_URL_GET_SITE}`;
  return axios.get(url);
};

const getThisSite = async () => {
  const url = `${API_URL_GET_SITE}${SITE_ID}`;
  return axios.get(url);
};

const getSite = async (siteId) => {
  const url = `${API_URL_GET_SITE}${siteId}`;
  return axios.get(url);
};

export { getSites, getThisSite, getSite };
