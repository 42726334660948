function FrameStyleCarousel({ thisSite, formData, setFormData }) {
  // prefer the default image type if it exists, else pick the first one
  let imageType = formData.imageType;
  imageType = thisSite.imageTypes[imageType]
    ? imageType
    : Object.keys(thisSite.imageTypes)[0];

  // prefer the default derivative type if it exists, else pick the first one
  let derivativeType = formData.derivativeType;
  derivativeType = thisSite.imageTypes[imageType].derivativeTypes[
    derivativeType
  ]
    ? derivativeType
    : Object.keys(thisSite.imageTypes[imageType].derivativeTypes)[0];

  const frameStyles =
    thisSite.imageTypes[imageType].derivativeTypes[derivativeType].frameStyles;
  const selected = formData.frameStyle;
  return (
    <div className="page-create_component_frame-style-carousel">
      {frameStyles.map((frameStyle) => (
        <button
          className={`page-create_component_frame-style-carousel-item ${
            frameStyle.frameStyleId === selected ? "selected" : ""
          }`}
          disabled={frameStyle.frameStyleId === selected}
          key={frameStyle.frameStyleId}
          onClick={() => {
            setFormData({ ...formData, frameStyle: frameStyle.frameStyleId });
          }}
        >
          <div className="page-create_component_frame-style-carousel-item-text">
            {frameStyle.frameStyleDisplayMessage}
          </div>
        </button>
      ))}
    </div>
  );
}

export default FrameStyleCarousel;
