import React from "react";

function ButtonSaveAs({ token, tokenAddress }) {
  const [isHover, setIsHover] = React.useState(false);
  tokenAddress = tokenAddress ? tokenAddress : token.address;

  return (
    <>
      <div
        className={`page-token_token-save-as-button ${isHover && "hover"}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div className={`page-token_token-button-icon ${isHover && "hover"}`} />
        <div className={`page-token_token-button-text ${isHover && "hover"}`}>
          {/* prompt download */}
          <a
            href={token.image}
            download={`${token.name}.png`}
            title={token.name}
            target="_blank"
            rel="noopener noreferrer"
          >
            download image
          </a>
        </div>
      </div>
    </>
  );
}

export default ButtonSaveAs;
