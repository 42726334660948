import React, { useEffect, useState } from "react";

function EngineInput({ handleApiRequestLifecycle, user }) {
  const [input, setInput] = useState(user);
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    if (isSubmitted) {
      setIsSubmitted(false);
      handleApiRequestLifecycle("handleEngineRequest", true, {
        random: "true",
        wallet: input,
      });
    }
  }, [handleApiRequestLifecycle, input, isSubmitted]);

  return (
    <>
      <input
        type="text"
        placeholder=">"
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setIsSubmitted(true);
          }
        }}
      />
      <div className="page-random_component_engine-form-input-box-button-container">
        <button
          onClick={() => {
            setIsSubmitted(true);
          }}
        >
          submit
        </button>
      </div>
    </>
  );
}

export default EngineInput;
