import React from "react";

const addCookies = (link) => {
  return `${link}?user=${localStorage.getItem(
    "user"
  )}&platform=${localStorage.getItem("platform")}`;
};

function CardCollectionItem({ site, inWallet, size = "medium" }) {
  const displayName = site.siteDisplay.siteDisplayName;
  const link = site.links.wagmicafe;
  const src = `https://095882290577-public.s3.amazonaws.com/${site.siteId}.png`;
  const cookieLink = addCookies(link);
  return (
    <div className="collection-item">
      <a href={cookieLink} target="_blank" rel="noopener noreferrer">
        <img src={src} alt={`${site.siteDisplay.siteTitle}`} />
        <div>
          <i className="fas fa-external-link-alt" />
          {displayName}
        </div>
      </a>
    </div>
  );
}

export default CardCollectionItem;
