function CardCallToAction({ site, input }) {
  const ctaObj = site.imageTypes[input.imageType].derivativeTypes[
    input.derivativeType
  ].frameStyles.find(
    (frameStyle) => frameStyle.frameStyleId === input.frameStyle
  ).callToActions;
  return ctaObj ? (
    <div className="component-card-call-to-action_cta-card-container">
      <div className="component-card-call-to-action_cta-card">
        <div className="component-card-call-to-action_cta-card-header-container">
          <div className="component-card-call-to-action_cta-card-header">
            BONUS
          </div>
        </div>

        <div className="component-card-call-to-action_cta-card-desc-container">
          <div className="component-card-call-to-action_cta-card-desc-text">
            {ctaObj.merchandise.callToActionDescription}
          </div>
        </div>

        <div className="component-card-call-to-action_cta-card-qr-container ">
          <a href={ctaObj.merchandise.callToActionLinks.main}>
            <img
              src={ctaObj.merchandise.callToActionLinks.qrImgPath}
              alt="QR"
            />
          </a>
        </div>

        <div className="component-card-call-to-action_cta-card-link-container">
          <a href={ctaObj.merchandise.callToActionLinks.main}>Link to Order</a>
        </div>
      </div>
    </div>
  ) : null;
}

export default CardCallToAction;
