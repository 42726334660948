import React from "react";
import ModalMint from "../ModalMint/ModalMint";

function ButtonMint({ token, tokenAddress, message = "mint now" }) {
  const [showModal, setShowModal] = React.useState(false);
  // component specific
  const [isHover, setIsHover] = React.useState(false);

  tokenAddress = tokenAddress ? tokenAddress : token.address;

  return (
    <>
      <div
        className={`page-token_token-mint-button ${isHover && "hover"}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <div className={`page-token_token-button-icon ${isHover && "hover"}`} />
        <div className={`page-token_token-button-text ${isHover && "hover"}`}>
          {message}
        </div>
      </div>
      {showModal && <ModalMint {...{ token, tokenAddress, setShowModal }} />}
    </>
  );
}

export default ButtonMint;
