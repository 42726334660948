import React, { useRef, useState, useEffect } from "react";
import {
  getUserTokensByCollection,
  getUserTokens,
  getUserNftsByPlatforms,
} from "../../../api/wagmi-users-api";
import Footer from "../../components/Footer/Footer";
import { handleApiRequest } from "../../../api";
import WagmiCafeHeader from "../../components/Headers/Headers";
import { getThisSite } from "../../../api/wagmi-api-v2";
import { useParams } from "react-router-dom";
import Loading from "../../screens/Loading/Loading";
import Error from "../../screens/Error/Error";
import { SITE_ID, TREASURY_PK } from "../../../config";
import InventoryItem from "../PageLibrary/components/InventoryItem";
import ButtonSaveAs from "../../components/ButtonSaveAs/ButtonSaveAs";
import BarProjectInfo from "../../components/BarProjectInfo/BarProjectInfo";
import {
  separateTokens,
  handleMissingImage,
  filterTokens,
} from "../../../utils";
import ButtonMint from "../../components/ButtonMint/ButtonMint";
const axios = require("axios");

const translate = (short) => {
  switch (short) {
    case "bg":
      return "background";
    case "pfp":
      return "profile picture";
    default:
      return short;
  }
};

function NftView({
  token,
  type,
  inputItems,
  setInputItems,
  limit,
  enabled = true,
}) {
  return (
    <div
      key={token.image}
      className="page-create-v2_selector-item-container"
      onClick={() => {
        if (enabled) {
          setInputItems({
            ...inputItems,
            [type]: [
              ...(inputItems[type]
                ? inputItems[type].length >= limit
                  ? inputItems[type].slice(1)
                  : inputItems[type]
                : []),
              convert2ImageUrl(token),
            ],
          });
        }
      }}
    >
      <img
        className="page-create-v2_nft-item"
        src={token.imageUrl}
        alt={token.tokenAddress}
        onError={({ currentTarget }) => {
          handleMissingImage(currentTarget, "token");
        }}
      />
    </div>
  );
}

function SelectorImageView({ tokens, traitType, filters, selected }) {
  tokens = filterTokens(tokens, filters);
  const [hover, setHover] = useState(false);
  const [i, setI] = useState(0);
  const n = tokens.length;
  const p = 1500;
  useEffect(() => {
    const interval = setInterval(() => {
      setI((i) => (i + 1) % n || 0);
    }, p);
    return () => clearInterval(interval);
  }, [n, selected]);

  return (
    tokens.length > 0 && (
      <div className="page-create-v2_selector-item">
        <img
          className={`page-create-v2_selector-item-img ${
            hover ? "hover" : ""
          } ${selected ? "selected" : ""}`}
          src={tokens[i].image}
          alt="tokens"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
        {!hover && !selected && (
          <div className="page-create-v2_selector-item-title">
            {tokens[i].attributes.find((a) => a.trait_type === traitType).value}
          </div>
        )}
      </div>
    )
  );
}

const convert2ImageUrl = (nft) => {
  return { ...nft, imageUrl: nft.image || nft.imageUrl };
};

function SectionOutput({
  selectedImageType,
  selectedDerivativeType,
  selectedFrameStyleId,
  inputItems,
  siteObject,
  isOutputLoading,
  isOutputSubmitted,
  outputData,
  setIsOutputLoading,
  setIsOutputSubmitted,
  setOutputData,
  setError,
}) {
  // env
  const platform = localStorage.getItem("platform");
  const user = localStorage.getItem("user");

  useEffect(() => {
    let polling = true;
    let flags = {};
    let lambdaFunction = "handleEngineRequest";
    setIsOutputLoading(true);
    setIsOutputSubmitted(true);
    let params = {
      input: {
        collection: SITE_ID,
        platform,
        userId: user,
        imageType: selectedImageType,
        derivativeType: selectedDerivativeType,
        frameStyle: selectedFrameStyleId,
        items: inputItems,
      },
      siteObject: siteObject,
      nfts: [],
      jobId: polling ? "" : null,
    };
    handleApiRequest(lambdaFunction, params, flags).then((response) => {
      let status = response.status;
      switch (status) {
        case 202:
          let jobId = response.data.jobId;
          let pollCount = 0;
          let interval = setInterval(() => {
            handleApiRequest(lambdaFunction, { ...params, jobId })
              .then((response) => {
                let status = response.status;
                switch (status) {
                  case 200:
                    axios
                      .get(response.data.message.metaplex_uri)
                      .then((response2) => {
                        setOutputData({
                          outputJson: response.data.message.json,
                          outputMetaplex: response.data.message.metaplex_uri,
                          outputKey: response.data.message.deriv_key,
                          outputImage: response.data.message.location,
                          token: response2.data,
                          // get the string after the last / and before the .
                          tokenAddress: response.data.message.metaplex_uri
                            .split("/")
                            [
                              response.data.message.metaplex_uri.split("/")
                                .length - 1
                            ].split(".")[0],
                        });
                        setIsOutputLoading(false);
                        clearInterval(interval);
                      });
                    break;
                  case 202:
                    pollCount++;
                    if (pollCount > 120) {
                      setError("timeout");
                      setIsOutputLoading(false);
                      setIsOutputSubmitted(false);
                      clearInterval(interval);
                    }
                    break;
                  default:
                    setIsOutputLoading(false);
                    clearInterval(interval);
                    break;
                }
              })
              .catch((error) => {
                setError(error);
                setIsOutputLoading(false);
                setIsOutputSubmitted(false);
                clearInterval(interval);
              });
          }, 1000);
          break;

        case 200:
          setIsOutputLoading(false);
          setOutputData({
            outputJson: response.data.message.json,
            outputKey: response.data.message.deriv_key,
            outputImage: response.data.message.location,
          });
          break;

        default:
          setIsOutputLoading(false);
          setIsOutputSubmitted(false);
          break;
      }
    });
  }, [
    inputItems,
    platform,
    selectedDerivativeType,
    selectedFrameStyleId,
    selectedImageType,
    setError,
    setIsOutputLoading,
    setIsOutputSubmitted,
    setOutputData,
    siteObject,
    user,
  ]);

  return outputData ? (
    <div className="page-create_output-container">
      <div className="page-create_header-container">
        <div className="page-create_header">output</div>
      </div>
      <div className="page-create_output">
        <div className="page-create_output-item">
          <div className="margin-wrapper">
            <BarProjectInfo />
          </div>
          <InventoryItem token={outputData.token} />
          <ButtonSaveAs token={outputData.token} />
          <ButtonMint token={outputData.token} message={"mint to wallet"} />
        </div>
      </div>
    </div>
  ) : null;
}

function PageCreateV2() {
  // env and api
  const userId = localStorage.getItem("user");
  const platform = localStorage.getItem("platform");

  // helper for scrolling the page
  const bottomRef = useRef(null);
  const executeScroll = () => {
    setTimeout(() => {
      bottomRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };

  // handle manual image inputs
  const handleManualImageSubmit = (e) => {
    setTokens([
      {
        image: inputValue,
        imageUrl: inputValue,
        attributes: [],
        description: "Raw image",
        name: "Image from URL",
        properties: [],
        symbol: "RAW",
      },
      ...tokens,
    ]);
  };

  const handleReset = () => {
    setError(null);
    setImageType("");
    setDerivativeType("");
    setFrameStyleId("");
    setFrameStyleCriteria([]);
    setSatisfiedNftInputs(0);
    setInputItems({});
    setInputComplete(false);
    setHasUserHitSubmit(false);
    setInputValue("");
    setOutputData();
  };

  // state
  const [error, setError] = useState(null);
  const [isSuccessTreasuryTokens, setIsSuccessTreasuryTokens] = useState(false);
  const [isLoadingTreasuryTokens, setIsLoadingTreasuryTokens] = useState(true);
  const [isSuccessUserTokens, setIsSuccessUserTokens] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [siteObject, setSiteObject] = useState(null);
  const [isSuccessSiteObject, setIsSuccessSiteObject] = useState(false);

  // user input state
  const [selectedImageType, setImageType] = useState("");
  const [selectedDerivativeType, setDerivativeType] = useState("");
  const [selectedFrameStyleId, setFrameStyleId] = useState("");
  const [frameStyleCriteria, setFrameStyleCriteria] = useState([]);
  const [, setSatisfiedNftInputs] = useState(0);
  const [inputItems, setInputItems] = useState({});
  const [inputComplete, setInputComplete] = useState(false);
  const [hasUserHitSubmit, setHasUserHitSubmit] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // output state
  const [isOutputLoading, setIsOutputLoading] = useState(false);
  const [isOutputSubmitted, setIsOutputSubmitted] = useState(false);
  const [outputData, setOutputData] = useState();

  // outputstate
  const [tknsByImageType, setTksByImageType] = useState({});
  const [tknsByDerivativeType, setTknsByDerivativeType] = useState({});
  const [tknsByFrameStyleId, setTknsByFrameStyleId] = useState({});

  // GET user tokens (TREASURY_PK)
  useEffect(() => {
    getUserTokensByCollection(platform, TREASURY_PK, SITE_ID)
      .then((response) => {
        setIsSuccessTreasuryTokens(true);
        setIsLoadingTreasuryTokens(false);
        const { tknsByImageType, tknsByDerivativeType, tknsByFrameStyleId } =
          separateTokens(response.data);
        setTksByImageType(tknsByImageType);
        setTknsByDerivativeType(tknsByDerivativeType);
        setTknsByFrameStyleId(tknsByFrameStyleId);
      })
      .catch((error) => {
        setIsSuccessTreasuryTokens(false);
        setIsLoadingTreasuryTokens(false);
        setError(error);
      });
  }, [platform]);

  // // GET user tokens (this user)
  // useEffect(() => {
  //   getUserTokens(platform, userId)
  //     .then((response) => {
  //       setIsSuccessUserTokens(true);
  //       setTokens(response.data);
  //     })
  //     .catch((error) => {
  //       setIsSuccessUserTokens(false);
  //       setError(error);
  //     });
  // }, [platform, userId]);

  // GET site
  useEffect(() => {
    getThisSite()
      .then((response) => {
        setIsSuccessSiteObject(true);
        setSiteObject(response.data.site);
      })
      .catch((error) => {
        setIsSuccessSiteObject(false);
        setError(error);
      });
  }, []);

  //  GET users/{platform}/{userId}
  React.useEffect(() => {
    if (platform && userId) {
      getUserNftsByPlatforms(platform, userId, true)
        .then((response) => {
          console.log(response.data.items);
          setIsSuccessUserTokens(true);
          setTokens(response.data.items);
        })
        .catch((error) => {
          setIsSuccessUserTokens(false);
          setError(error);
        });
    }
  }, [platform, userId]);

  useEffect(() => {
    if (selectedImageType && selectedDerivativeType && selectedFrameStyleId) {
      const derivativeTypeObject =
        siteObject.imageTypes[selectedImageType].derivativeTypes[
          selectedDerivativeType
        ];
      const requiredImagesObject = derivativeTypeObject.frameStyles.find(
        (frameStyle) => frameStyle.frameStyleId === selectedFrameStyleId
      );
      setFrameStyleCriteria(requiredImagesObject.requiredImages);
    }
  }, [
    selectedDerivativeType,
    selectedFrameStyleId,
    selectedImageType,
    siteObject,
  ]);

  useEffect(() => {
    if (frameStyleCriteria.length > 0) {
      for (let req of frameStyleCriteria) {
        const min = req.requiredNumber.lower;
        if (
          inputItems[req.imageType] &&
          inputItems[req.imageType].length >= min
        ) {
          setSatisfiedNftInputs((satisfiedNftInputs) => satisfiedNftInputs + 1);
        }
      }
    }
  }, [frameStyleCriteria, inputItems]);

  useEffect(() => {
    if (frameStyleCriteria.length > 0) {
      let isSatisified = false;
      for (let req of frameStyleCriteria) {
        const min = req.requiredNumber.lower;
        if (
          inputItems[req.imageType] &&
          inputItems[req.imageType].length >= min
        ) {
          isSatisified = true;
        }
      }
      setInputComplete(isSatisified);
    }
  }, [frameStyleCriteria, inputItems]);

  return isLoadingTreasuryTokens ? (
    <Loading />
  ) : !isSuccessTreasuryTokens ? (
    <Error {...{ error }} />
  ) : (
    <>
      <div className="global-page-container">
        <WagmiCafeHeader pageName={"Create"} />
        <div className="page-create_header-container"></div>
        <BarProjectInfo />
        <>
          <div className="page-create-v2_selector">
            <div className="page-create-v2_selector-title">
              select image type
            </div>
            <div className="page-create-v2_selector-carousel-container">
              <div className="page-create-v2_selector-carousel">
                {Object.keys(tknsByImageType).map((imageType) => (
                  <div
                    key={imageType}
                    onClick={() => {
                      if (!outputData) {
                        setImageType(imageType);
                        setDerivativeType("");
                        setFrameStyleId("");
                        executeScroll();
                      }
                    }}
                  >
                    <SelectorImageView
                      tokens={tknsByImageType[imageType]}
                      traitType={"image type"}
                      filters={[]}
                      selected={selectedImageType === imageType}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
        {selectedImageType && (
          <>
            <div className="page-create_spacer"></div>
            <div className="page-create-v2_selector">
              <div className="page-create-v2_selector-title">
                select derivative type
              </div>
              <div className="page-create-v2_selector-carousel-container">
                <div className="page-create-v2_selector-carousel">
                  {Object.keys(tknsByDerivativeType).map((derivType) => (
                    <div
                      key={derivType}
                      className="page-create-v2_selector-item-container"
                      onClick={() => {
                        if (!outputData) {
                          setDerivativeType(derivType);
                          setFrameStyleId("");
                          executeScroll();
                        }
                      }}
                    >
                      <SelectorImageView
                        tokens={tknsByDerivativeType[derivType]}
                        traitType={"derivative type"}
                        filters={[
                          { traitType: "image type", value: selectedImageType },
                        ]}
                        selected={selectedDerivativeType === derivType}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        {selectedImageType && selectedDerivativeType && (
          <div className="page-create-v2_selector">
            <div className="page-create-v2_selector-title">
              select frame style
            </div>
            <div className="page-create-v2_selector-carousel-container">
              <div className="page-create-v2_selector-carousel">
                {Object.keys(tknsByFrameStyleId).map((frameId) => (
                  <div
                    key={frameId}
                    className="page-create-v2_selector-item-container"
                    onClick={() => {
                      if (!outputData) {
                        setFrameStyleId(frameId);
                        executeScroll();
                      }
                    }}
                    selected={selectedFrameStyleId === frameId}
                  >
                    <SelectorImageView
                      tokens={tknsByFrameStyleId[frameId]}
                      traitType={"frame style"}
                      filters={[
                        { traitType: "image type", value: selectedImageType },
                        {
                          traitType: "derivative type",
                          value: selectedDerivativeType,
                        },
                      ]}
                      selected={selectedFrameStyleId === frameId}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {selectedImageType &&
          selectedDerivativeType &&
          selectedFrameStyleId &&
          isSuccessUserTokens &&
          frameStyleCriteria.length > 0 && (
            <div className="page-create-v2_selector-nft-container">
              {frameStyleCriteria.map((requiredImage, i) => (
                <div className="page-create-v2_selector nft">
                  <div className="page-create-v2_selector-title">
                    {`select ${translate(requiredImage.imageType)} nft`}
                  </div>
                  <div
                    className={`page-create-v2_selector-subtitle ${
                      (inputItems[requiredImage.imageType] &&
                        inputItems[requiredImage.imageType].length >=
                          requiredImage.requiredNumber.lower &&
                        "green") ||
                      (requiredImage.requiredNumber.lower < 1 && "green")
                    }`}
                  >
                    requires ({requiredImage.requiredNumber.lower} {"≤"}{" "}
                    {inputItems[requiredImage.imageType]
                      ? inputItems[requiredImage.imageType].length
                      : 0}{" "}
                    {"≤"} {requiredImage.requiredNumber.upper}) images
                  </div>
                  <div className="page-create-v2_selector-carousel-container">
                    <div className="page-create-v2_selector-carousel">
                      {tokens.map((token) => (
                        <NftView
                          key={token.tokenAddress}
                          token={token}
                          type={requiredImage.imageType}
                          inputItems={inputItems}
                          setInputItems={setInputItems}
                          limit={requiredImage.requiredNumber.upper}
                          enabled={!outputData}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="page-create_component_carousel-item-enter-manually-row">
                    <input
                      className="page-create_component_carousel-item-enter-manually-search-bar"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") handleManualImageSubmit(e);
                      }}
                      placeholder="enter image url (optional)"
                      onChange={(e) => setInputValue(e.target.value)}
                      value={inputValue}
                    ></input>
                    <button
                      className="page-create_component_carousel-item-enter-manually-search-bar-go-button"
                      onClick={(e) => handleManualImageSubmit(e)}
                    >
                      go
                    </button>
                  </div>
                </div>
              ))}
              {inputComplete && isSuccessSiteObject && (
                <div className="page-create_submit-button-container">
                  {isOutputLoading ? (
                    <button
                      className="page-create_submit-button-purple"
                      disabled={isOutputLoading}
                    >
                      <div className="loading-svg" />
                    </button>
                  ) : outputData ? (
                    <button
                      className="page-create_submit-button-white"
                      disabled={isOutputLoading}
                      onClick={() => {
                        handleReset();
                      }}
                    >
                      reset
                    </button>
                  ) : (
                    <button
                      className="page-create_submit-button-purple"
                      disabled={isOutputLoading}
                      onClick={() => {
                        setHasUserHitSubmit(true);
                      }}
                    >
                      create
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        <div ref={bottomRef}></div>
        {hasUserHitSubmit && (
          <SectionOutput
            {...{
              selectedImageType,
              selectedDerivativeType,
              selectedFrameStyleId,
              inputItems,
              siteObject,
              isOutputLoading,
              isOutputSubmitted,
              outputData,
              setIsOutputLoading,
              setIsOutputSubmitted,
              setOutputData,
              setError,
            }}
          >
            {executeScroll()}
          </SectionOutput>
        )}
      </div>
      {outputData && <Footer />}
    </>
  );
}

export default PageCreateV2;
