import React, { useState } from "react";

const parse = (str) => {
  if (!str) return "not connected";
  const first = str.substring(0, 4);
  const last = str.substring(str.length - 4);
  return `${first}...${last}`;
};

const flip = (status) => {
  return status === "closed" ? "open" : "closed";
};

// get localstorage platform and user
const platform = localStorage.getItem("platform");
const user = localStorage.getItem("user");

function TopNavWallet({ handleRefreshUser }) {
  const [chevron, setChevron] = useState("closed");
  const [refreshing, setRefreshing] = useState(false);
  return (
    <div className="component-top-nav_wallet-container">
      <div
        className={`component-top-nav_wallet ${chevron}`}
        onClick={() => {
          setChevron(flip(chevron));
        }}
      >
        <div className="component-top-nav_wallet-icon">
          <i className="fas fa-wallet" />
        </div>
        <div className="component-top-nav_wallet-address">{parse(user)}</div>
        <div className={`component-top-nav_wallet-chevron ${chevron}`}>
          <i className="fas fa-chevron-down" />
        </div>
      </div>
      <div className="component-top-nav_wallet-dropdown-container">
        <div className={`component-top-nav_wallet-dropdown ${chevron}`}>
          <div
            className="component-top-nav_wallet-dropdown-action"
            onClick={() => {
              setRefreshing(true);
              handleRefreshUser();
            }}
          >
            {user && (
              <span>
                <div className="component-top-nav_wallet-dropdown-item-text">
                  Refresh Wallet
                </div>
                <i className={`fas fa-sync ${refreshing && "active"} `} />
              </span>
            )}
          </div>
          <div className="component-top-nav_wallet-dropdown-item">
            <a href="/login">
              <div className="component-top-nav_wallet-dropdown-item-text">
                Change Wallet
              </div>
              <i className="fas fa-wallet" />
            </a>
          </div>
          {user && (
            <>
              <hr className="divider" />
              <div className="component-top-nav_wallet-dropdown-item">
                <a href={`/derivatives/users/${platform}/${user}`}>
                  <div className="component-top-nav_wallet-dropdown-item-text">
                    Go to Your Library
                  </div>
                  <i className="fas fa-book" />
                </a>
              </div>
            </>
          )}
          {user && (
            <>
              <hr className="divider" />
              <div className="component-top-nav_wallet-dropdown-item">
                <a
                  href={`https://solscan.io/account/${user}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="component-top-nav_wallet-dropdown-item-text">
                    View Wallet on Explorer
                  </div>
                  <i className="fas fa-external-link-alt" />
                </a>
              </div>
            </>
          )}
          {user && (
            <div className="component-top-nav_wallet-dropdown-item">
              <a
                href={`https://hyperspace.xyz/account/${user}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="component-top-nav_wallet-dropdown-item-text">
                  View NFTs on Hyperspace.xyz
                </div>
                <i className="fas fa-external-link-alt" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopNavWallet;
