import React, { Fragment } from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import PageCreate from "./pages/PageCreate/PageCreate";
import PageCreateV2 from "./pages/PageCreateV2/PageCreateV2";
import PageLogin from "./pages/PageLogin/PageLogin";
import PageLanding from "./pages/PageLanding/PageLanding";
import PageToken from "./pages/PageToken/PageToken";
import PageCollections from "./pages/PageCollections/PageCollections";
import PageRandom from "./pages/PageRandom/PageRandom";
import TopNav from "./components/Nav/TopNav";
import PageLibrary from "./pages/PageLibrary/PageLibrary";
import { SITE_ID } from "../config";
const thisSiteId = SITE_ID;

function App() {
  // if the URL contains "user" and "platform" e.g. <URL>/?user=vpww&platform=solana, then use those values
  const search = useLocation().search;
  let routedUser = new URLSearchParams(search).get("user");
  let routedPlatform = new URLSearchParams(search).get("platform");

  // if the url was referred by a site where they did not have their user and platform set, then reset them to undefined.
  if (routedUser === "null") {
    routedUser = undefined;
  }
  if (routedPlatform === "null") {
    routedPlatform = undefined;
  }
  // set localStorage values to the routed values
  if (routedUser && routedPlatform) {
    localStorage.setItem("user", routedUser);
    localStorage.setItem("platform", routedPlatform);
  }

  let user = localStorage.getItem("user");
  let platform = localStorage.getItem("platform");

  // page props
  const DefaultPageProps = {};
  const PageCreateProps = { ...DefaultPageProps, page: "create" };
  const PageCreateV2Props = { ...DefaultPageProps, page: "create" };
  const PageLoginProps = { ...DefaultPageProps, page: "login" };
  const PageCollectionsProps = { ...DefaultPageProps, page: "collections" };
  const PageRandomProps = { ...DefaultPageProps, page: "engine" };
  const PageLibraryProps = { ...DefaultPageProps, page: "inventory" };
  const PageLandingProps = { ...DefaultPageProps, page: "landing" };
  const PageTokenProps = { ...DefaultPageProps, page: "token" };

  // private routes are routes that require a user and platform to be set
  const PrivateRoute = () => {
    const platform = localStorage.getItem("platform");
    const user = localStorage.getItem("user");
    const auth = platform && user;
    return auth ? <Outlet /> : <Navigate to="/login" />;
  };

  const pageObjects = {
    landing: {
      pageName: `${thisSiteId}.cafe`,
      path: "/",
    },
    create: {
      pageName: "Create",
      path: "/app",
    },
    collections: {
      pageName: "All Collections",
      path: "/collections",
    },
    library: {
      pageName: "Your Library",
      path: `/derivatives/users/${platform}/${user}`,
    },
    wallet: {
      pageName: "change wallet",
      path: "/login",
    },
  };

  return (
    <Fragment>
      <div className="App" id="app">
        <TopNav {...{ pageObjects }} />
        <Routes>
          <Route exact path="/app" element={<PrivateRoute />}>
            <Route
              path="/app"
              element={<PageCreateV2 {...PageCreateV2Props} />}
            />
          </Route>
          <Route exact path="/app-v1" element={<PrivateRoute />}>
            <Route
              path="/app-v1"
              element={<PageCreate {...PageCreateProps} />}
            />
          </Route>
          <Route path="/" element={<PageLanding {...PageLandingProps} />} />
          <Route
            exact
            path="/derivatives/users/:platform/:userId"
            element={<PrivateRoute />}
          >
            <Route
              path="/derivatives/users/:platform/:userId"
              element={<PageLibrary {...PageLibraryProps} />}
            />
          </Route>
          <Route
            path="/collections"
            element={<PageCollections {...PageCollectionsProps} />}
          />
          <Route path="/login" element={<PageLogin {...PageLoginProps} />} />
          <Route path="/engine" element={<PageRandom {...PageRandomProps} />} />
          <Route
            path="/token/:tokenAddress"
            element={<PageToken {...PageTokenProps} />}
          />
        </Routes>
      </div>
    </Fragment>
  );
}

export default App;
