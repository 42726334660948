import AppFormSubmitButton from "./AppFormSubmitButton";
import AppFormStagingPreview from "./AppFormStagingPreview";
function AppFormStagingArea({
  formData,
  userItems,
  requiredImages,
  appFormSubmitButtonProps,
}) {
  return requiredImages.length > 0 ? (
    <div className="page-create_component_app-form-staging-area-selected-container">
      <div className="page-create_component_root-image-preview">
        {requiredImages.map((requiredImage, i) => (
          <div
            className="page-create_component_root-image-preview-image-type-container"
            key={i}
          >
            <div className="page-create_component_root-image-preview-image-type-title">
              {`input ${requiredImage.imageType}`}:
            </div>
            {formData.images[requiredImage.imageType] ? (
              <div className="page-create_component_root-image-preview-image-type-row">
                {formData.images[requiredImage.imageType].map((uuid, i) => {
                  return (
                    <img
                      key={i}
                      alt={uuid}
                      src={
                        userItems.find((item) => item.uuid === uuid).imageUrl
                      }
                    />
                  );
                })}
                <hr className="divider grey" />
              </div>
            ) : (
              <div className="page-create_component_root-image-preview-empty">
                <div className="page-create_component_root-image-preview-empty-message"></div>
              </div>
            )}
          </div>
        ))}
      </div>
      <AppFormSubmitButton {...appFormSubmitButtonProps} />
      <AppFormStagingPreview />
      <AppFormSubmitButton {...appFormSubmitButtonProps} />
    </div>
  ) : null;
}

export default AppFormStagingArea;
