import { handleRevertFormData } from "../utils";
function ImageTypeCarousel({ thisSite, formData, setFormData }) {
  const imageTypes = Object.keys(thisSite.imageTypes);
  // filter out the imageTypes that do not have any derivativeTypes
  const filteredImageTypes = imageTypes.filter((imageType) => {
    return (
      Object.keys(thisSite.imageTypes[imageType].derivativeTypes).length > 0
    );
  });
  const selected = formData.imageType;
  return (
    <div className="page-create_component_image-type-carousel">
      {filteredImageTypes.map((imageType) => (
        <button
          className={`page-create_component_image-type-carousel-item ${
            imageType === selected ? "selected" : ""
          }`}
          disabled={imageType === selected}
          onClick={() => {
            // implicity reset the formData
            setFormData({
              ...handleRevertFormData(formData, thisSite, { imageType }),
              imageType,
            });
          }}
          key={imageType}
        >
          <div className="page-create_component_image-type-carousel-item-text">
            {imageType}
          </div>
        </button>
      ))}
    </div>
  );
}

export default ImageTypeCarousel;
