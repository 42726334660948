import React from "react";
import { API_URL_GET_METADATA } from "../../../config";
import ButtonSocialLink from "../ButtonSocialLink/ButtonSocialLink";
const axios = require("axios");
function Footer() {
  // get footer
  const [metadata, setMetadata] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  // GET metadata
  // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
  React.useEffect(() => {
    axios
      .get(API_URL_GET_METADATA + "footer")
      .then((response) => {
        setMetadata(response.data);
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
        setIsSuccess(false);
      });
    // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function
    return () => {
      console.log("cleanup");
    };
  }, []);
  return !isSuccess ? null : isLoading ? (
    <div>loading...</div>
  ) : (
    <div className="component-footer_footer">
      <div className="component-footer_footer-container">
        <div className="component-footer_title-section">
          <div className="component-footer_title-section-title">
            {metadata.footerTitle}
          </div>
          <div className="component-footer_title-section-subtitle">
            {metadata.footerSubtitle}
          </div>
          <div>
            <ButtonSocialLink
              socialName="twitter"
              url={metadata.socials.twitter}
            />
          </div>
        </div>
        <div className="component-footer_main-sections">
          {Object.keys(metadata.sections.main).map((section, key) => {
            return (
              <div className="component-footer_main-section" key={key}>
                <div className="component-footer_main-section-title">
                  {section}
                </div>
                <div className="component-footer_main-section-items">
                  {metadata.sections.main[section].items.map((item, i) => {
                    return (
                      <a
                        key={i}
                        className="component-footer_main-section-item-link"
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={`component-footer_main-section-item ${
                            !item.live && "not-live"
                          }`}
                        >
                          {item.title}
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="component-footer_aux-section">
          <div className="component-footer_aux-section-title">
            {metadata.sections.aux.Title}
          </div>
          <div className="component-footer_aux-section-items">
            {metadata.sections.aux.items.map((item, i) => {
              return (
                <a
                  className="component-footer_aux-section-item-link"
                  href={item.link}
                  target="_blank"
                  key={i}
                  rel="noopener noreferrer"
                >
                  <div
                    className={`component-footer_aux-section-item ${
                      !item.live && "not-live"
                    }`}
                  >
                    {item.title}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
