import React from "react";
import InventoryItem from "../../PageLibrary/components/InventoryItem";
const axios = require("axios");

function AppOutputDisplay({ outputData }) {
  const [token, setToken] = React.useState(null);
  const [isSuccessToken, setIsSuccessToken] = React.useState(false);
  const [isLoadingToken, setIsLoadingToken] = React.useState(true);

  // useEffect
  React.useEffect(() => {
    axios
      .get(outputData.outputMetaplex)
      .then((response) => {
        setToken(response.data);
        setIsSuccessToken(true);
        setIsLoadingToken(false);
      })
      .catch((error) => {
        setIsSuccessToken(false);
        setIsLoadingToken(false);
      });
  }, [outputData]);

  return isLoadingToken ? (
    "loading"
  ) : !isSuccessToken ? (
    "failed"
  ) : (
    <div className="page-create_output-inventory-item-container">
      <InventoryItem token={token} />
    </div>
  );
}

export default AppOutputDisplay;
