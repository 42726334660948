import React from "react";

function CardNftItems({ items }) {
  let nfts = [].concat.apply(
    [],
    Object.keys(items).map((key) => items[key])
  );
  return nfts.map((nft, index) => {
    return <CardNftItem key={index} nft={nft} />;
  });
}

function CardNftItem({ nft }) {
  return (
    <div className={`common_collection-item`}>
      <a
        className="common_collection-item-link"
        href={`https://solscan.io/token/${nft.tokenAddress}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="common_collection-item-image"
          src={nft.imageUrl}
          alt={"test"}
        />
        <div className="common_collection-item-name">{nft.name}</div>
      </a>
    </div>
  );
}

export { CardNftItem, CardNftItems };
