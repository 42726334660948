import React, { useCallback, useEffect, useState } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { SystemProgram, Transaction } from "@solana/web3.js";
import { handleMintApiRequest } from "../../../api";
import { TREASURY_PK } from "../../../config";
import {
  searchSiteObjectForFrameStyleWithInputObject,
  parseMetaplexFromImageUri,
  generateKey,
} from "../../../utils";
import { getDerivative } from "../../../api/wagmi-users-api";
import { getSite } from "../../../api/wagmi-api-v2";
const LAMPORTS_PER_SOL = 1000000000;

function ModalEscapeButton({ setShowModal, isMinting }) {
  return (
    <div
      className="modal-esc-close-button"
      onClick={() => {
        if (!isMinting) {
          setShowModal(false);
        }
      }}
    >
      +
    </div>
  );
}

function InfoBar({ messages }) {
  return <div className="mint-modal-info-bar">{messages}</div>;
}
function InfoBars({ messages }) {
  return messages.map((message) => {
    return message && <InfoBar messages={message} />;
  });
}

// step 1. confirm payment (optional)
const handleConfirmPayment = async (params) => {
  const {
    signature,
    derivativeObject,
    setIsPaymentConfirmed,
    setButtonMessage,
  } = params;

  const fn = "confirm";
  const data = {
    uri: parseMetaplexFromImageUri(derivativeObject.image_uri),
  };
  return new Promise((resolve, reject) => {
    handleMintApiRequest(fn, signature, data)
      .then((response) => {
        let pollCount = 0;
        let status = response.status;
        setButtonMessage(response.data.message);
        switch (status) {
          case 202:
            let interval = setInterval(() => {
              handleMintApiRequest(fn, signature, data, {
                poll: true,
              })
                .then((response) => {
                  let status = response.status;
                  setButtonMessage(response.data.message);
                  switch (status) {
                    case 200:
                      console.log("payment confirmed");
                      clearInterval(interval);
                      setIsPaymentConfirmed(true);
                      resolve();
                      break;
                    case 202:
                      console.log("payment still processing");
                      console.log(pollCount);
                      pollCount = pollCount + 1;
                      break;
                    default:
                      console.log("payment failed");
                      clearInterval(interval);
                      reject();
                      break;
                  }
                })
                .catch((error) => {
                  console.log(error);
                  clearInterval(interval);
                  reject();
                });
            }, 1000);
            break;

          case 200:
            console.log("payment confirmed");
            setIsPaymentConfirmed(true);
            resolve();
            break;

          default:
            break;
        }
      })
      .catch((error) => {
        reject();
      });
  });
};

// step 2. mint to user
const handleMintDelivery = async (params) => {
  const { signature, derivativeObject, destination, setButtonMessage } = params;
  const fn = "deliver";
  const data = {
    uri: parseMetaplexFromImageUri(derivativeObject.image_uri),
    destId: destination,
  };
  return new Promise((resolve, reject) => {
    handleMintApiRequest(fn, signature, data).then((response) => {
      let pollCount = 0;
      let status = response.status;
      console.log(status);
      setButtonMessage(response.data.message);
      switch (status) {
        case 202:
          let interval = setInterval(() => {
            handleMintApiRequest(fn, signature, data, {
              poll: true,
            }).then((response) => {
              let status = response.status;
              console.log(status);
              setButtonMessage(response.data.message);
              switch (status) {
                case 200:
                  console.log("mint confirmed");
                  clearInterval(interval);
                  resolve();
                  break;
                case 202:
                  console.log("mint still processing");
                  console.log(pollCount);
                  pollCount = pollCount + 1;
                  break;
                default:
                  console.log("mint failed");
                  clearInterval(interval);
                  reject();
                  break;
              }
            });
          }, 1000);
          break;

        case 200:
          console.log("mint confirmed");
          resolve();
          break;

        default:
          break;
      }
    });
  });
};

export const ActionButton: FC = ({
  sendTransaction,
  publicKey,
  magicNumber,
  mintPrice,
  derivativeObject,
  isMinting,
  setIsMinting,
}) => {
  // enviroment
  const { connection } = useConnection();
  const lamports = mintPrice * LAMPORTS_PER_SOL - magicNumber;
  console.log({ lamports });

  // state receipt
  const initButtonMessage = "mint";

  // state
  const [buttonMessage, setButtonMessage] = useState(initButtonMessage);
  const [successDelivery, setSuccessDelivery] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);

  const onClick = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    setIsMinting(true);

    // step 1. send lamports to treasury
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: TREASURY_PK,
        lamports,
      })
    );
    setButtonMessage("sending transaction");
    const signature = await sendTransaction(transaction, connection);
    setButtonMessage("transaction sent");

    // step 2. confirm transaction on blockchain
    setButtonMessage("waiting for confirmation");
    try {
      await connection.confirmTransaction(signature, "processed");
    } catch (error) {
      setIsError(true);
      setIsMinting(false);
      console.log({ error });
    }

    // step 3. confirm payment on backend
    const handleConfirmPaymentProps = {
      signature,
      derivativeObject,
      setIsPaymentConfirmed,
      setButtonMessage,
    };
    try {
      await handleConfirmPayment(handleConfirmPaymentProps);
    } catch (error) {
      console.log({ error });
      setIsError(true);
      setIsMinting(false);
      setButtonMessage("payment failed");
      return;
    }

    // step 4. send nft to user
    const destination = publicKey.toString();
    const handleMintDeliveryProps = {
      signature,
      derivativeObject,
      destination,
      setButtonMessage,
    };
    try {
      await handleMintDelivery(handleMintDeliveryProps);
      setSuccessDelivery(true);
    } catch (error) {
      console.log({ error });
      setIsError(true);
      setIsMinting(false);
      setButtonMessage("delivery failed");
    }
    setIsMinting(false);
  }, [
    connection,
    derivativeObject,
    lamports,
    publicKey,
    sendTransaction,
    setIsMinting,
  ]);

  return (
    publicKey && (
      <div onClick={onClick} disabled={!publicKey || isMinting}>
        {buttonMessage}
      </div>
    )
  );
};

function ModalMint({ token, tokenAddress, setShowModal }) {
  // set up wallet
  const { publicKey, sendTransaction } = useWallet();

  // action bar state handlers
  const [isMinting, setIsMinting] = useState(false);

  // site
  const [siteObject, setSite] = React.useState(null);
  const [isSuccessSite, setIsSuccessSite] = React.useState(false);
  const [isLoadingSite, setIsLoadingSite] = React.useState(true);
  // derivative
  const [derivativeObject, setDerivative] = React.useState(null);
  const [isSuccessDerivative, setIsSuccessDerivative] = React.useState(false);
  const [isLoadingDerivative, setIsLoadingDerivative] = React.useState(true);

  console.log({ token });

  // useEffect
  React.useEffect(() => {
    getSite(
      token.attributes.find(
        (attribute) => attribute.trait_type === "collection"
      ).value
    )
      .then((response) => {
        setSite(response.data.site);
        setIsSuccessSite(true);
        setIsLoadingSite(false);
      })
      .catch((error) => {
        setIsSuccessSite(false);
        setIsLoadingSite(false);
      });
  }, [tokenAddress]);
  // useEffect
  React.useEffect(() => {
    getDerivative(generateKey(token, tokenAddress))
      .then((response) => {
        setDerivative(JSON.parse(response.data));
        setIsSuccessDerivative(true);
        setIsLoadingDerivative(false);
      })
      .catch((error) => {
        setIsSuccessDerivative(false);
        setIsLoadingDerivative(false);
      });
  }, [token, tokenAddress]);

  // parse derivative data
  let frameStyle;
  let magicNumber;
  let mintPrice;
  let hasAlreadyMinted;
  if (derivativeObject && siteObject) {
    console.log({ derivativeObject });
    console.log({ siteObject });
    frameStyle = searchSiteObjectForFrameStyleWithInputObject(
      siteObject,
      derivativeObject.input
    );
    magicNumber = frameStyle.magicNumber;
    mintPrice = frameStyle.mintPrice;
    hasAlreadyMinted = derivativeObject.hasPaid ? true : false;
  }

  // function to handle modal escapes
  const handleEsc = useCallback(
    (event) => {
      if (!isMinting && event.key === "Escape") {
        setShowModal(false);
      }
    },
    [isMinting, setShowModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEsc, false);
    return () => {
      document.removeEventListener("keydown", handleEsc, false);
    };
  }, [handleEsc]);

  return isLoadingSite || isLoadingDerivative ? (
    <div className="modal-login-adapter-modal">
      <div className="modal-login-overlay-container modal-login-adapter-modal-fade-in">
        loading
      </div>
    </div>
  ) : !isSuccessSite || !isSuccessDerivative ? (
    <div className="modal-login-adapter-modal">
      <div className="modal-login-overlay-container modal-login-adapter-modal-fade-in">
        error
      </div>
    </div>
  ) : (
    <div className="modal-login-adapter-modal">
      <div className="modal-login-overlay-container modal-login-adapter-modal-fade-in">
        <div className="modal-login-wrapper">
          <div className="mint-modal-header">Do not close this window</div>
          <InfoBars
            messages={[
              hasAlreadyMinted ? "You have already minted this item." : null,
              `After wallet approval, payment transaction will be verified, and your NFT will be sent to you in a separate transaction.`,
            ]}
          />
          <div className="mint-modal-header">{mintPrice} SOL</div>
          {!publicKey ? (
            <div className="page-token_token-mint-button">
              <WalletMultiButton />
            </div>
          ) : (
            <div className="page-token_token-mint-button">
              <ActionButton
                {...{
                  sendTransaction,
                  publicKey,
                  magicNumber,
                  mintPrice,
                  derivativeObject,
                  isMinting,
                  setIsMinting,
                }}
              />
            </div>
          )}
          <ModalEscapeButton {...{ setShowModal, isMinting }} />
          <div className="modal-footer-text">estimated time: 2 minutes</div>
        </div>
      </div>
    </div>
  );
}

export default ModalMint;
