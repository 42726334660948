import { randomizeList } from "../../../../utils";

const handleRevertFormData = (formData, siteObject, objectToRevertTo) => {
  const objectKeyToRevertTo = Object.keys(objectToRevertTo)[0];
  switch (objectKeyToRevertTo) {
    case "imageType":
      const newImageType = objectToRevertTo.imageType;
      let newDerivativeType = Object.keys(
        siteObject.imageTypes[newImageType].derivativeTypes
      )[0];
      let newFrameStyle =
        siteObject.imageTypes[newImageType].derivativeTypes[newDerivativeType]
          .frameStyles[0].frameStyleId;
      return {
        imageType: newImageType,
        derivativeType: newDerivativeType,
        frameStyle: newFrameStyle,
        images: [],
      };
    case "derivativeType":
      const newImageType2 = formData.imageType;
      const newDerivativeType2 = objectToRevertTo.derivativeType;
      const newFrameStyle2 =
        siteObject.imageTypes[newImageType2].derivativeTypes[newDerivativeType2]
          .frameStyles[0].frameStyleId;
      return {
        imageType: newImageType2,
        derivativeType: newDerivativeType2,
        frameStyle: newFrameStyle2,
        images: [],
      };
    default:
      return formData;
  }
};

// util functions
const handleSetInitialFormData = (siteObject, userItems) => {
  return { ...findRandomInput(siteObject), images: [] };
};

const handlePopulateItems = (items, formData) => {
  let requestItems = {};
  for (let key in formData.images) {
    // pfp, bg, gif
    for (let itemUuid of formData.images[key]) {
      // find the items that match the uuid in items
      let item = items.find((item) => item.uuid === itemUuid);
      requestItems[key] = [...(requestItems[key] || []), item];
    }
  }
  return requestItems;
};

const findRandomInput = (siteObject) => {
  let imageTypeKeys = Object.keys(siteObject.imageTypes);
  let compatibleImageTypesAndDerivativeTypes = [];
  for (let imageType of imageTypeKeys) {
    for (let derivativeType of Object.keys(
      siteObject.imageTypes[imageType].derivativeTypes
    )) {
      compatibleImageTypesAndDerivativeTypes.push([imageType, derivativeType]);
    }
  }
  let compatibleImageTypesAndDerivativeTypesAndFrameStyleIds = [];
  for (let combinations of compatibleImageTypesAndDerivativeTypes) {
    for (let frameStyle of siteObject.imageTypes[combinations[0]]
      .derivativeTypes[combinations[1]].frameStyles) {
      compatibleImageTypesAndDerivativeTypesAndFrameStyleIds.push([
        ...combinations,
        frameStyle.frameStyleId,
      ]);
    }
  }

  let ins = randomizeList(
    compatibleImageTypesAndDerivativeTypesAndFrameStyleIds
  )[0];

  let input = {
    imageType: ins[0],
    derivativeType: ins[1],
    frameStyle: ins[2],
  };
  return input;
};

export {
  handleSetInitialFormData,
  handlePopulateItems,
  findRandomInput,
  handleRevertFormData,
};
