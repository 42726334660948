// require dotenv
import {
  STAGE,
  BACKEND_API_REGION,
  API_URL_MINT,
  BACKEND_API_ID,
} from "../config";
const dotenv = require("dotenv");
dotenv.config();
const axios = require("axios");
const API_ENDPOINT = `https://${BACKEND_API_ID}.execute-api.${BACKEND_API_REGION}.amazonaws.com/${STAGE}`;

const contstructQueryString = (flags) => {
  let queryString = "";
  for (let i = 0; i < Object.values(flags).length; i++) {
    if (i === 0) {
      queryString += "?";
    }
    queryString += `${Object.keys(flags)[i]}=${Object.values(flags)[i]}`;
    if (i !== Object.values(flags).length - 1) {
      queryString += "&";
    }
  }
  return queryString;
};

const handleApiRequest = async (lambda, data, flags = {}) => {
  let qs = contstructQueryString(flags);
  const response = await axios({
    method: "post",
    url: `${API_ENDPOINT}/${lambda}${qs}`,
    data,
  });
  return response;
};

const handleMintApiRequest = async (fn, txId, data, flags = {}) => {
  let qs = contstructQueryString(flags);
  const response = await axios({
    method: "post",
    url: `${API_URL_MINT}${fn}/${txId}${qs}`,
    data,
  });
  return response;
};

const createJobRandomImageFromWallet = async (walletAddress) => {
  const params = { walletAddress };
  const response = axios.post(
    `${API_ENDPOINT}/handleCreateJobRandomImageFromWallet`,
    params
  );
  return response;
};
const pollRandomImageFromWallet = async (filename) => {
  const params = { filename };
  const response = axios.post(
    `${API_ENDPOINT}/handlePollRandomImageFromWallet`,
    params
  );
  return response;
};

export {
  handleApiRequest,
  handleMintApiRequest,
  createJobRandomImageFromWallet,
  pollRandomImageFromWallet,
};
