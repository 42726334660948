import React from "react";
import ButtonRoute from "../../components/ButtonRoute/ButtonRoute";
import CallToAction from "../../components/CardCallToAction/CardCallToAction";
import CardCollectionInfoBox from "../../components/CardCollectionInfoBox/CardCollectionInfoBox";
import Error from "../../screens/Error/Error";
import Loading from "../../screens/Loading/Loading";
import Footer from "../../components/Footer/Footer";
import WagmiCafeHeader from "../../components/Headers/Headers";
import ImageTypeCarousel from "./components/ImageTypeCarousel";
import DerivativeTypeCarousel from "./components/DerivativeTypeCarousel";
import FrameStyleCarousel from "./components/FrameStyleCarousel";
import ImageSelector from "./components/ImageSelector";
import EngineLoading from "./components/EngineLoading";
import AppFormStagingArea from "./components/AppFormStagingArea";
import AppOutputDisplay from "./components/AppOutputDisplay";
import InventoryItem from "../PageLibrary/components/InventoryItem";
import ButtonMint from "../../components/ButtonMint/ButtonMint";
import {
  USER_API_REGION,
  USER_API_ID,
  STAGE,
  BACKEND_API_ID,
  BACKEND_API_REGION,
  SITE_ID,
} from "../../../config";
import {
  checkCompleteForm,
  handleGetAllPossibleCombinations,
} from "../../../utils";
import { handleSetInitialFormData } from "./utils";
const axios = require("axios");

function PageCreate() {
  // env and storage
  const platform = localStorage.getItem("platform");
  const user = localStorage.getItem("user");
  const thisSiteId = SITE_ID;
  const getUserUrl = `https://${USER_API_ID}.execute-api.${USER_API_REGION}.amazonaws.com/${STAGE}/users/${platform}/${user}?cache=true`;
  const getSitesUrl = `https://${BACKEND_API_ID}.execute-api.${BACKEND_API_REGION}.amazonaws.com/${STAGE}/sites`;
  // state
  const [thisSite, setThisSite] = React.useState({});
  const [userItems, setUserItems] = React.useState([]);
  const [isUserLoading, setIsUserLoading] = React.useState(true);
  const [isUserSuccess, setIsUserSuccess] = React.useState(false);
  const [isSiteLoading, setIsSiteLoading] = React.useState(true);
  const [isSiteSuccess, setIsSiteSuccess] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [statusString, setStatusString] = React.useState("");

  // output
  const [outputData, setOutputData] = React.useState({});
  const [isOutputSubmitted, setIsOutputSubmitted] = React.useState(false);
  const [isOutputLoading, setIsOutputLoading] = React.useState(true);

  // form-specific state
  const [formData, setFormData] = React.useState({});
  const [requiredImages, setRequiredImages] = React.useState([]);
  const [areRequiredImagesSatisified, setAreRequiredImagesSatisified] =
    React.useState(false);

  const handleResetFormData = () => {
    setFormData({ ...formData, images: [] });
    setIsOutputSubmitted(false);
    setIsOutputLoading(false);
    setOutputData({});
    setStatusString("");
  };

  //  GET users/{platform}/{user}
  React.useEffect(() => {
    if (platform && user) {
      setIsUserLoading(true);
      axios
        .get(getUserUrl)
        .then((response) => {
          setUserItems(response.data.items);
          setIsUserSuccess(true);
          setIsUserLoading(false);
        })
        .catch((error) => {
          setIsUserSuccess(false);
          setIsUserLoading(false);
          setError(error);
        });
    }
  }, [getUserUrl, platform, user]);

  // GET sites
  React.useEffect(() => {
    setIsSiteLoading(true);
    axios
      .get(getSitesUrl)
      .then((response) => {
        let sites = response.data.sites;
        let thisSite = sites[thisSiteId];
        setThisSite(thisSite);
        setIsSiteSuccess(true);
        setIsSiteLoading(false);
      })
      .then((error) => {
        setIsSiteSuccess(false);
        setIsSiteLoading(false);
        setError(error);
      });
  }, [getSitesUrl, thisSiteId]);

  // populate form with random inputs to begin
  React.useEffect(() => {
    if (isUserSuccess && isSiteSuccess) {
      setFormData(handleSetInitialFormData(thisSite, userItems));
    }
  }, [isSiteSuccess, isUserSuccess, thisSite, userItems]);

  // populate userItems with overrideItems if they exist.
  React.useEffect(() => {
    if (isUserSuccess && isSiteSuccess) {
      let itemsToAddToUserItems = [];
      const combinations = handleGetAllPossibleCombinations(thisSite);
      for (let combination of combinations) {
        const allFrameStyles =
          thisSite.imageTypes[combination.imageType].derivativeTypes[
            combination.derivativeType
          ].frameStyles;
        for (let frameStyle of allFrameStyles) {
          if (frameStyle.frameStyleId === combination.frameStyle) {
            for (let image of frameStyle.requiredImages) {
              if (image.overrideImages) {
                itemsToAddToUserItems = [
                  ...itemsToAddToUserItems,
                  ...image.overrideImages,
                ];
              }
            }
          }
        }
      }
      setUserItems([...userItems, ...itemsToAddToUserItems]);
    }
  }, [isSiteSuccess, isUserSuccess, thisSite, userItems]);

  // check if form is satisfied on every change
  React.useEffect(() => {
    setAreRequiredImagesSatisified(checkCompleteForm(requiredImages, formData));
  }, [requiredImages, formData]);

  // refresh the set of required images on every change
  React.useEffect(() => {
    if (Object.keys(formData).length > 0) {
      let imageType = formData.imageType;
      imageType = thisSite.imageTypes[imageType]
        ? imageType
        : Object.keys(thisSite.imageTypes)[0];
      let derivativeType = formData.derivativeType;
      derivativeType = thisSite.imageTypes[imageType].derivativeTypes[
        derivativeType
      ]
        ? derivativeType
        : Object.keys(thisSite.imageTypes[imageType].derivativeTypes)[0];
      const derivativeTypeObject =
        thisSite.imageTypes[imageType].derivativeTypes[derivativeType];
      const requiredImagesObject = derivativeTypeObject.frameStyles.find(
        (frameStyle) => frameStyle.frameStyleId === formData.frameStyle
      );
      setRequiredImages(requiredImagesObject.requiredImages);
    }
  }, [formData, thisSite]);

  // render
  const basicProps = { thisSite, formData, setFormData };
  const imageSelectorProps = {
    ...basicProps,
    userItems,
    setUserItems,
    requiredImages,
  };
  const appFormSubmitButtonProps = {
    setIsOutputLoading,
    setIsOutputSubmitted,
    thisSiteId,
    platform,
    user,
    formData,
    userItems,
    thisSite,
    setOutputData,
    setError,
    areRequiredImagesSatisified,
  };

  return isUserLoading || isSiteLoading ? (
    <Loading />
  ) : error ? (
    <Error {...{ error }} />
  ) : (
    <>
      <div className="global-page-container">
        <WagmiCafeHeader />
        <CardCollectionInfoBox />
        <div className="page-create_app-form-container">
          <div className="page-create_app-form-inputs">
            <div
              className="page-create_app-form-inputs-page"
              onClick={() => {
                isOutputSubmitted && handleResetFormData();
              }}
            >
              <div className="page-create_root-top-row">
                <a
                  className="page-create_root-wallet-addr-box-container"
                  href={`/derivatives/users/${platform}/${user}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="page-create_root-wallet-addr-box-content">
                    {user}
                  </div>
                  <div className="page-create_root-wallet-addr-box-platform">
                    {platform}
                  </div>
                </a>
              </div>
              <div className="page-create_select-type-carousel-row-descriptor">
                image type:
              </div>
              <ImageTypeCarousel {...basicProps} />
              <div className="page-create_select-type-carousel-row-descriptor">
                derivative type:
              </div>
              <DerivativeTypeCarousel {...basicProps} />
              <div className="page-create_select-type-carousel-row-descriptor">
                frame style:
              </div>
              <FrameStyleCarousel {...basicProps} />
              <ImageSelector {...imageSelectorProps} />
            </div>
          </div>
          <div className="page-create_app-form-staging-area">
            {!isOutputSubmitted && (
              <AppFormStagingArea
                formData={formData}
                userItems={userItems}
                requiredImages={requiredImages}
                appFormSubmitButtonProps={appFormSubmitButtonProps}
              />
            )}
            {!outputData.outputImage ? (
              isOutputLoading &&
              isOutputSubmitted && (
                <EngineLoading input={formData} thisSite={thisSite} />
              )
            ) : (
              <>
                <div className="page-create_output-inventory-item-container">
                  <InventoryItem token={outputData.token} />
                </div>
                {outputData.outputMetaplex && (
                  <ButtonMint
                    {...{
                      token: outputData.token,
                      tokenAddress: outputData.tokenAddress,
                    }}
                  />
                )}
                <div className="common_route-buttons-container">
                  <CallToAction {...{ site: thisSite, input: formData }} />
                  <ButtonRoute
                    content="view all collections"
                    routePath="/collections"
                  />
                  <ButtonRoute
                    content="reset"
                    specialHandler={handleResetFormData}
                    variant="vanilla"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PageCreate;
