import React from "react";

function ButtonSocialLink({ socialName, url }) {
  return (
    <div className="component_button-social-link_button-container">
      <a
        className="component_button-social-link_button-link"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={`component_button-social-link_button-icon ${socialName}`}
        />
      </a>
    </div>
  );
}

export default ButtonSocialLink;
