import ItemCarousel from "./ItemCarousel";

function ImageSelector({
  thisSite,
  setFormData,
  userItems,
  formData,
  setUserItems,
  requiredImages,
}) {
  return (
    <div className="page-create_component_image-selector">
      {requiredImages.map((requiredImage, i) => (
        <ItemCarousel
          i={i}
          key={i}
          items={userItems}
          requiredImage={requiredImage}
          formData={formData}
          setFormData={setFormData}
          setUserItems={setUserItems}
          thisSite={thisSite}
        />
      ))}
    </div>
  );
}

export default ImageSelector;
