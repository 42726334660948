import React from "react";

function PageHeader({ pageName }) {
  return (
    <div className="component-headers_header-container">
      <h1>{pageName}</h1>
    </div>
  );
}

export default PageHeader;
