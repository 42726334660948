function ChainList({ enginePlatform, setEnginePlatform, supportedPlatforms }) {
  return (
    <div className="page-random_component_chains-container-header">
      {supportedPlatforms.map((chain, i) => {
        return (
          <div
            key={i}
            className={`page-random_component_chain-icon-container ${
              chain === enginePlatform && "selected"
            }`}
            onClick={() => {
              setEnginePlatform(chain);
            }}
          >
            {chain}
          </div>
        );
      })}
    </div>
  );
}
export default ChainList;
