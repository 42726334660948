import { BUCKET_CAFE_ASSETS } from "../config";

const findFirstSquareImageFromGetTokensRequest = (tokens) => {
  for (let token of tokens) {
    for (let att of token.attributes) {
      if (att.trait_type === "image type" && att.value === "squares") {
        return token;
      }
    }
  }
};

const handleMissingImage = (currentTarget, imageType) => {
  let src;
  switch (imageType) {
    default:
      src = `${process.env.PUBLIC_URL}/assets/images/placeholders/missing_image.png`;
      break;
  }
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = src;
};

const handleSetLocalStorage = (params) => {
  for (let i = 0; i < params.length; i++) {
    localStorage.setItem(params[i].key, params[i].value);
  }
};

const handleGetSupportedPlatforms = (sites) => {
  return [...new Set(Object.values(sites).map((site) => site.blockchain))];
};

const randomizeList = (list) => {
  let newList = [];
  while (list.length > 0) {
    let randomIndex = Math.floor(Math.random() * list.length);
    newList.push(list[randomIndex]);
    list.splice(randomIndex, 1);
  }
  return newList;
};

const handleGetAllPossibleCombinations = (siteObject) => {
  const combinations = [];
  for (let imageType of Object.keys(siteObject.imageTypes)) {
    for (let derivativeType of Object.keys(
      siteObject.imageTypes[imageType].derivativeTypes
    )) {
      for (let frameStyle of siteObject.imageTypes[imageType].derivativeTypes[
        derivativeType
      ].frameStyles) {
        combinations.push({
          imageType: imageType,
          derivativeType: derivativeType,
          frameStyle: frameStyle.frameStyleId,
        });
      }
    }
  }
  return combinations;
};

const checkCompleteForm = (requiredImages, formData) => {
  if (!formData.images) {
    return false;
  }
  let truths = [];
  for (let requiredImage of requiredImages) {
    let lower = requiredImage.requiredNumber.lower;
    let imageType = requiredImage.imageType;
    if (!formData.images[imageType]) {
      if (lower === 0) {
        truths.push(true);
        continue;
      } else {
        return false;
      }
    }
    truths.push(formData.images[imageType].length >= lower);
  }
  return truths.every((element) => element === true);
};

const getAllUniqueCallsToAction = (siteObject) => {
  const combinations = handleGetAllPossibleCombinations(siteObject);

  let callsToActions = combinations.map((combination) => {
    const allFrameStyles =
      siteObject.imageTypes[combination.imageType].derivativeTypes[
        combination.derivativeType
      ].frameStyles;
    const callToActions = allFrameStyles.find(
      (frameStyle) => frameStyle.frameStyleId === combination.frameStyle
    ).callToActions;
    return callToActions;
  });
  // remove all undefineds
  callsToActions = callsToActions.filter(
    (callToAction) => callToAction !== undefined
  );
  let seenCallToActions = [];
  for (let callToAction of callsToActions) {
    Object.keys(callToAction).forEach((key) => {
      if (
        seenCallToActions
          .map((sCta) => sCta.callToActionId)
          .includes(callToAction[key].callToActionId)
      ) {
        return null;
      } else {
        seenCallToActions.push(callToAction[key]);
        return callToAction;
      }
    });
  }
  return seenCallToActions;
};

const partitionSiteItemsFromNonSiteItemsGivenSiteAndUserItems = (
  thisSite,
  userItems
) => {
  let thisSiteItems = [];
  let notThisSiteItems = [];
  for (let i = 0; i < userItems.length; i++) {
    if (
      thisSite.collection.uniqueIdentifier.uuids.includes(
        userItems[i].updateAuthority
      )
    ) {
      thisSiteItems.push(userItems[i]);
    } else {
      notThisSiteItems.push(userItems[i]);
    }
  }
  return [thisSiteItems, notThisSiteItems];
};

const filterOutNonSiteItems = (thisSite, userItems) => {
  const updateAuthorities = thisSite.collection.uniqueIdentifier.uuids;
  const filterObjects = updateAuthorities.map((updateAuthority, i) => {
    return {
      filterer: `^${updateAuthority}$`,
      type: "regex",
      index: i,
      displayName: `${thisSite.siteId} ${i}`,
    };
  });

  const filteredItems = userItems.filter((item) => {
    return filterObjects.some((filterObject) => {
      return new RegExp(filterObject.filterer).test(item.updateAuthority);
    });
  });
  return filteredItems;
};

const parseFormatting = (text) => {
  // find all characters between "__" and "__"
  const matches = text.match(/__(.*?)__/g);
  if (matches) {
    // return text before first match, then first match in <u> tag, then text after last match
    let textBefore = text.split(matches[0])[0];
    let textAfter = text.split(matches[matches.length - 1])[1];
    let textBetween = matches[0].replace(/__/g, "");
    return (
      <>
        {textBefore}
        <u>{textBetween}</u>
        {textAfter}
      </>
    );
  }
  return text;
};

const partitionSiteItemsFromNonSiteItemsGivenSiteAndUserItemsV2 = (
  thisSite,
  userItems,
  filters
) => {
  const updateAuthorities = filters;
  const filterObjects = updateAuthorities.map((updateAuthority, i) => {
    return {
      filterer: `^${updateAuthority}$`,
      type: "regex",
      index: i,
      displayName: `${thisSite.siteId} ${i}`,
    };
  });

  let matching = [];
  let nonMatching = [];
  for (let item of userItems) {
    if (
      filterObjects.some((filterObject) => {
        return new RegExp(filterObject.filterer).test(item.updateAuthority);
      })
    ) {
      matching.push(item);
    } else {
      nonMatching.push(item);
    }
  }
  return [matching, nonMatching];
};

const aOrAn = (str) => {
  return str.charAt(0) === "a" ||
    str.charAt(0) === "e" ||
    str.charAt(0) === "i" ||
    str.charAt(0) === "o" ||
    str.charAt(0) === "u"
    ? "an"
    : "a";
};

const getAllSitesInWallet = (userItems, sitesList, platform = undefined) => {
  let sitesListWallet = [];
  let sitesOnPlatform = [];
  for (let site of sitesList) {
    if (site.blockchain === platform) {
      sitesOnPlatform.push(site);
    }
  }

  let wildcard = ".*";
  for (let site of sitesList) {
    if (site.collection.uniqueIdentifier.uuids.includes(wildcard)) {
      sitesListWallet.push(site);
    }
    loopB: for (let nft of userItems) {
      for (let siteUuid of site.collection.uniqueIdentifier.uuids) {
        if (siteUuid === nft.updateAuthority) {
          sitesListWallet.push(site);
          break loopB;
        }
      }
    }
  }

  return [sitesListWallet, sitesOnPlatform];
};

const filterNonCustomCollections = (sitesList) => {
  // loop thru the object and remove values that have site.isCustom = false
  let customSites = {};
  Object.keys(sitesList).forEach((key) => {
    if (sitesList[key].isCustom) {
      customSites[key] = sitesList[key];
    }
  });
  return customSites;
};

const searchSiteObjectForFrameStyleWithInputObject = (siteObject, input) => {
  if (!siteObject || !input) return {};
  return (
    siteObject.imageTypes[input.imageType].derivativeTypes[
      input.derivativeType
    ].frameStyles.find((frameStyle) => {
      return frameStyle.frameStyleId === input.frameStyle;
    }) || {}
  );
};

const parseMetaplexFromImageUri = (imageUri) => {
  return `https://${BUCKET_CAFE_ASSETS}.s3.amazonaws.com/tokens/${imageUri
    .split("/")
    .pop()
    .replace(".png", ".json")}`;
};

const convertToCamelCase = (str) => {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};

const convertTraitArrayToObj = (token) => {
  let traits = {};
  for (let trait of token.attributes) {
    traits[convertToCamelCase(trait.trait_type)] = trait.value;
  }
  return traits;
};

const generateKey = (token, tokenAddress) => {
  let traits = convertTraitArrayToObj(token);
  const { collection, imageType, derivativeType, frameStyle, sourceWallet } =
    traits;
  return `user_images-solana-${sourceWallet}-${collection}-${imageType}-${derivativeType}-${frameStyle}-${tokenAddress}`;
};

const separateTokens = (tokens) => {
  let tknsByImageType = {};
  let tknsByDerivativeType = {};
  let tknsByFrameStyleId = {};

  for (let token of tokens) {
    let attributes = token.attributes;
    for (let attribute of attributes) {
      if (attribute.trait_type === "image type") {
        tknsByImageType[attribute.value] = [
          ...(tknsByImageType[attribute.value] || []),
          token,
        ];
      }
      if (attribute.trait_type === "derivative type") {
        tknsByDerivativeType[attribute.value] = [
          ...(tknsByDerivativeType[attribute.value] || []),
          token,
        ];
      }
      if (attribute.trait_type === "frame style") {
        tknsByFrameStyleId[attribute.value] = [
          ...(tknsByFrameStyleId[attribute.value] || []),
          token,
        ];
      }
    }
  }
  return { tknsByImageType, tknsByDerivativeType, tknsByFrameStyleId };
};

const filterTokens = (tokens, filters) => {
  let tmpTokens = [];
  for (let token of tokens) {
    let attributes = token.attributes;
    let matches = true;
    for (let attribute of attributes) {
      for (let filter of filters) {
        let { traitType, value } = filter;
        if (attribute.trait_type === traitType && attribute.value !== value) {
          matches = false;
        }
      }
    }
    if (matches) tmpTokens.push(token);
  }
  tokens = filters.length > 0 ? tmpTokens : tokens;
  return tokens;
};

export {
  handleSetLocalStorage,
  handleGetSupportedPlatforms,
  randomizeList,
  handleGetAllPossibleCombinations,
  checkCompleteForm,
  getAllUniqueCallsToAction,
  filterOutNonSiteItems,
  convertToCamelCase,
  generateKey,
  convertTraitArrayToObj,
  getAllSitesInWallet,
  partitionSiteItemsFromNonSiteItemsGivenSiteAndUserItems,
  partitionSiteItemsFromNonSiteItemsGivenSiteAndUserItemsV2,
  aOrAn,
  filterNonCustomCollections,
  searchSiteObjectForFrameStyleWithInputObject,
  parseMetaplexFromImageUri,
  handleMissingImage,
  findFirstSquareImageFromGetTokensRequest,
  parseFormatting,
  separateTokens,
  filterTokens,
};
