import React, { FC, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
// import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
// import { useConnection } from "@solana/wallet-adapter-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { handleSetLocalStorage } from "../../../utils";
// import { SystemProgram, Transaction } from "@solana/web3.js";

export const WalletSolana: FC = () => {
  const { publicKey } = useWallet();

  useEffect(() => {
    if (publicKey) {
      handleSetLocalStorage([
        { key: "platform", value: "solana" },
        { key: "user", value: publicKey.toBase58() },
      ]);
      return <Navigate to="/" />;
    }
  }, [publicKey]);
  return !publicKey ? (
    <div className="solana-wallet">
      <WalletMultiButton
        style={{ justifyContent: "center" }}
        children={"Connect SOLANA Wallet"}
      />
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default WalletSolana;
