import React from "react";
import ReactDOM from "react-dom";
import "./app/styles/styles.css";
import Dapp from "./app/Dapp";
import reportWebVitals from "./app/reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <Router basename="/">
    <Dapp />
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
