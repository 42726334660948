import React from "react";

function Error({ error }) {
  console.log(error);
  return (
    <div className="error">
      <h1>Error</h1>
      <h2>{error ? error.message : "unknown error"}</h2>
    </div>
  );
}

export default Error;
