import React from "react";
import Footer from "../../components/Footer/Footer";
import PageHeader from "../../components/Headers/Headers";
import Error from "../../screens/Error/Error";
import Loading from "../../screens/Loading/Loading";
import CollectionItem from "../../components/CardCollectionItem/CardCollectionItem";
import { getSites } from "../../../api/wagmi-api-v2";

function PageCollections() {
  // state
  const [error, setError] = React.useState(null);
  const [sites, setSites] = React.useState([]);
  const [isSuccessSite, setIsSuccessSite] = React.useState(false);
  const [isLoadingSites, setIsLoadingSites] = React.useState(true);

  // component-specific
  const sitesList = Object.values(sites);

  // GET sites
  React.useEffect(() => {
    getSites()
      .then((response) => {
        setIsSuccessSite(true);
        setIsLoadingSites(false);
        setSites(response.data.sites);
      })
      .catch((error) => {
        setIsSuccessSite(false);
        setIsLoadingSites(false);
        setError(error);
      });
  }, []);

  return isLoadingSites ? (
    <Loading />
  ) : !isSuccessSite ? (
    <Error {...{ error }} />
  ) : (
    <>
      <div className="global-page-container">
        <PageHeader pageName={"Collections"} />
        <div className="page-collections_items-grid-list-container">
          <div className="page-collections_collection-item-grid">
            {sitesList.map((site, index) => {
              return <CollectionItem site={site} size="small" key={index} />;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PageCollections;
