import React, { useMemo } from "react";
import App from "./App";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
} from "@solana/wallet-adapter-wallets";

function Dapp() {
  // @todo provide a custom RPC endpoint
  const net = process.env.REACT_APP_STAGE === "prod" ? "Mainnet" : "Devnet";
  const network = WalletAdapterNetwork[net];
  // const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolletWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
    ],
    []
  );
  return (
    <ConnectionProvider endpoint={endpoint}>
      {/* <WalletProvider wallets={wallets} autoConnect> */}
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>
          <App />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default Dapp;
