// wagmi-user-api
export const USER_API_REGION = process.env.REACT_APP_USER_API_REGION;
export const USER_API_ID = process.env.REACT_APP_USER_API_ID;
// wagmi-api-v2
export const BACKEND_API_REGION = process.env.REACT_APP_BACKEND_API_REGION;
export const BACKEND_API_ID = process.env.REACT_APP_BACKEND_API_ID;
// wagmi-mint-engine
export const MINT_API_REGION = process.env.REACT_APP_MINT_API_REGION;
export const MINT_API_ID = process.env.REACT_APP_MINT_API_ID;
// stage
export const STAGE = process.env.REACT_APP_STAGE;
// site id
export const SITE_ID = process.env.REACT_APP_SITE;
// constants
export const API_URL_GET_METADATA = `https://${process.env.REACT_APP_BACKEND_API_ID}.execute-api.${process.env.REACT_APP_BACKEND_API_REGION}.amazonaws.com/${process.env.REACT_APP_STAGE}/getMetadata/`;
export const API_URL_GET_DERIVATIVE = `https://${process.env.REACT_APP_USER_API_ID}.execute-api.${process.env.REACT_APP_USER_API_REGION}.amazonaws.com/${process.env.REACT_APP_STAGE}/derivatives/`;
export const API_URL_GET_SITE = `https://${process.env.REACT_APP_BACKEND_API_ID}.execute-api.${process.env.REACT_APP_BACKEND_API_REGION}.amazonaws.com/${process.env.REACT_APP_STAGE}/sites/`;
export const API_URL_GET_TOKEN = `https://${process.env.REACT_APP_USER_API_ID}.execute-api.${process.env.REACT_APP_USER_API_REGION}.amazonaws.com/${process.env.REACT_APP_STAGE}/token/`; // GET token/{tokenKey}
export const API_URL_GET_USER_TOKENS = `https://${process.env.REACT_APP_USER_API_ID}.execute-api.${process.env.REACT_APP_USER_API_REGION}.amazonaws.com/${process.env.REACT_APP_STAGE}/tokens/users/`; // GET tokens/{platform}/{userId}
export const API_URL_MINT = `https://${process.env.REACT_APP_MINT_API_ID}.execute-api.${process.env.REACT_APP_MINT_API_REGION}.amazonaws.com/${process.env.REACT_APP_STAGE}/mint/`;
export const API_URL_GET_NFTS = `https://${process.env.REACT_APP_USER_API_ID}.execute-api.${process.env.REACT_APP_USER_API_REGION}.amazonaws.com/${process.env.REACT_APP_STAGE}/users/`;

export const BUCKET_CAFE_ASSETS = `cafe-assets-${process.env.REACT_APP_STAGE}`;
export const BUCKET_CAFE_ASSETS_URI = `https://${BUCKET_CAFE_ASSETS}.s3.amazonaws.com`;
export const BUCKET_NFT_ASSETS = `wagmi.cafe`;
// wallets
export const TREASURY_PK = "cafeUdFbVD3KCwawgwBKzuu5qj4eHzUNdhhTSM1rFxe";
