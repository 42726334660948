import React, { useEffect, useState } from "react";
import { getSites } from "../../../../api/wagmi-api-v2";

function TopNavCollectionSearch() {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    getSites().then((res) => {
      setSites(Object.values(res.data.sites));
    });
  }, []);

  useEffect(() => {
    const results = handleSiteFilter(search, sites);
    setSearchResults(results);
  }, [search, sites]);

  // handlers
  const handleUserInput = (e) => {
    setSearch(e.target.value);
  };
  const handleSiteFilter = (search, sites) => {
    // only if search term is not empty and greater than 1 character
    if (search.length > 1) {
      return sites.filter((site) => {
        return (
          site.siteId.toLowerCase().includes(search.toLowerCase()) ||
          site.siteDisplay.siteDescription
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          site.siteDisplay.siteDisplayName
            .toLowerCase()
            .includes(search.toLowerCase())
        );
      });
    } else {
      return [];
    }
  };
  const handleUserKeyDown = (e) => {
    // handle escape key
    if (e.keyCode === 27) {
      setSearch("");
    }
  };
  return (
    <div className="component-top-nav_collection-search">
      {/* font-awesome magnify */}
      <div className="component-top-nav_collection-search-icon">
        <i className="fas fa-search" />
      </div>
      <input
        type="text"
        placeholder="search collection"
        onChange={handleUserInput}
        onKeyDown={handleUserKeyDown}
        value={search}
      />
      <div
        className={`component-top-nav_collection-search-dropdown ${
          searchResults.length < 1 && "closed"
        }`}
      >
        {searchResults.map((site, i) => {
          return (
            <div
              className="component-top-nav_collection-search-dropdown-item"
              key={i}
            >
              <div className="component-top-nav_collection-search-dropdown-item-details">
                <img src={site.assets.images.icon} alt="" />
                <a href={`${site.links.wagmicafe}`}>
                  {site.siteDisplay.siteDisplayName}
                </a>
              </div>
              <i className="fas fa-external-link-alt" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TopNavCollectionSearch;
