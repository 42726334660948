import React from "react";
import ModalLogin from "../../components/ModalLogin/ModalLogin";
import WalletSolana from "../../components/WalletSolana/WalletSolana";
import Footer from "../../components/Footer/Footer";
import WagmiCafeHeader from "../../components/Headers/Headers";
const loginObjects = {
  twitterHandle: {
    header: "Connect Twitter",
    content: [
      "We will use your username to find your profile picture and banner images",
      "If you have linked your Twitter to Bonfida, we will find your NFTs",
    ],
    placeholder: "Twitter Username",
    loginType: "Twitter",
    loginApiObj: {
      default: { regex: ".*", method: "bonfida-twitter" },
    },
  },
  addressSolana: {
    header: "Connect Address",
    content: [
      "We will use your wallet address to find your NFTs",
      "Bonfida .sol names are supported",
    ],
    placeholder: "Solana Address or .sol Domain",
    loginType: "Solana Address",
    loginApiObj: {
      default: { regex: ".*", method: "solana" },
      // eslint-disable-next-line no-useless-escape
      optional: { regex: `.*[\.]sol`, method: "bonfida-domain" },
    },
  },
  addressNear: {
    header: "Connect Address",
    content: ["We will use your wallet address to find your NFTs"],
    placeholder: "NEAR Address",
    loginType: "NEAR Address",
    loginApiObj: {
      // eslint-disable-next-line no-useless-escape
      default: { regex: `.*[\.]near`, method: "near" },
    },
  },
};

function PageLogin() {
  const [showModal, setShowModal] = React.useState(false);
  const [optionId, setOptionId] = React.useState("");
  function LoginButton({ content, optionId }) {
    return (
      <div className="page-login_login-option-container">
        <button
          className="common_button-spacebar"
          onClick={() => {
            setShowModal(true);
            setOptionId(optionId);
          }}
        >
          <div>{content}</div>
        </button>
      </div>
    );
  }
  return (
    <>
      <div className="header"></div>
      <WagmiCafeHeader />
      <div className="global-page-container">
        {showModal && (
          <ModalLogin
            loginObject={loginObjects[optionId]}
            setShowModal={setShowModal}
          />
        )}
        <div className="page-login_login-options-container">
          <WalletSolana />
          <LoginButton
            content={"Enter SOLANA Address"}
            optionId={"addressSolana"}
          />
          <LoginButton
            content={"Enter NEAR Address"}
            optionId={"addressNear"}
          />
          <LoginButton content={"Enter Twitter"} optionId={"twitterHandle"} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PageLogin;
